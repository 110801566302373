import React,{Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_INFORMATION_ATTRIBUTES,DELETE_INFORMATION_ATTRIBUTES} from '../Queries/informationAttribute';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import AddEditInformationAttribute from './AddEditInformationAttribute';
import InformationAttributeLib from '../Library/informationAttribute';

const InformationAttributes = (props) => {

    const informationAttributeLib = new InformationAttributeLib(props);

    const [selectedInformationAttributes,setSelectedInformationAttributes]   = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] = useState(false);
	const [showAdd,setShowAdd] 			         = useState(false);
    const [selectedInformationAttributeID,setSelectedInformationAttributeID] = useState(0);
    
    const {loading, error:getError, data:informationAttributesData}   = useQuery(GET_INFORMATION_ATTRIBUTES,{
        fetchPolicy: 'cache-and-network'
    });

    const [deleteInformationAttribute, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_INFORMATION_ATTRIBUTES,{	
		update(cache,response) {            
            
            informationAttributeLib.updateAfterDelete(cache, response);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const openCloseModal = (open,informationAttributeID) => {
        setSelectedInformationAttributeID(informationAttributeID);
        setShowAdd(open);
    }

    const showDelNotifi = () => {
        if(selectedInformationAttributes.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delInformationAttribute = (action) => {

        if(action){

            let informationAttributeIDs = selectedInformationAttributes.join(",");
            deleteInformationAttribute({variables:{informationAttributeIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteInformationAttribute = (e,informationAttributeID) => {

        var checked = e.target.checked;
        var arr = [...selectedInformationAttributes];

        if(!checked){
            for(let i in selectedInformationAttributes){
                if(selectedInformationAttributes[i] == informationAttributeID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [informationAttributeID,...arr];
        }

        setSelectedInformationAttributes(arr);
  
    }

    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("InformationAttributes")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("ItemName")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>

                                        {informationAttributesData && informationAttributesData.allInformationAttributes && informationAttributesData.allInformationAttributes.map((item,index) => {
                                            
                                            var checked = false;
                                            for(let i in selectedInformationAttributes){
                                                if(selectedInformationAttributes[i] == item.informationAttributeID)checked = true;
                                            }

                                            return (
                                                
                                                <div key={index} className="data-list-item">
                                                    <div className="data-list-item-content categories">
                                                        <div>{item.name}</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true,item.informationAttributeID)} className="edit-icon" src={edit} />
                                                            <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteInformationAttribute(e,item.informationAttributeID)} />
                                                        </div>
                                                    </div>
                                                </div> 
                                            )
                                            
                                        })}
                                        
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {informationAttributesData && informationAttributesData.allInformationAttributes && informationAttributesData.allInformationAttributes.length == 0 &&
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditInformationAttribute openCloseModal={openCloseModal} selectedInformationAttributeID={selectedInformationAttributeID} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delInformationAttribute} /> }	
        </div>
    )

}

export default withApollo(InformationAttributes);