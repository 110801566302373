import React,{ Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_PROJECT, GET_PROJECT} from '../Queries/project';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import ProjectLib from '../Library/project';
import { Editor } from '@tinymce/tinymce-react';
import ProjectImages from './ProjectImages';
import DevelopmentDate from './DevelopmentDate';
import ProjectVideo from './ProjectVideo';
import ProjectFiles from './ProjectFiles';
import ProjectHistory from './ProjectHistory';
import ProjectRealty from './ProjectRealty';
import {SERVER_URL,PROJECT_IMAGE_URL} from '../../../Config/index';


const AddEditProject = (props) => {

    const projectLib = new ProjectLib(props);

    const [selectedTab, setTab] = useState(1);
    const [mainPhoto, setMainPhoto] = useState("");
    const [formData,setFormData] = useState({
        
        projectID:props.selectedProjectID,
        name:"",
        photo:"",
        projectTypeID:0,
        energyClassID:0,
        active:1,
        mapZoom:12,
        mapLng:18.3499,
        mapLat:49.6782,
        description:"",
        perex:"",
        dateCompleted:"",
        goingToText:"",
        forSaleFrom:"",
        metaTitle:"",
        metaKeywords:"",
        metaDescription:"",
        informationAttributes:[],
        images:[],
        files:[]
    });

    const {data:projectData,loading:getLoading,error:getError} = useQuery(GET_PROJECT,{
        variables:{projectID: formData.projectID},
        fetchPolicy: 'network-only',
        onCompleted(data){

            projectLib.getData(data,formData.projectID,formData,setFormData);

            if(formData.projectID){
                setMainPhoto(SERVER_URL + "/" + PROJECT_IMAGE_URL + "/p-" + formData.projectID + "/velka_" + data.project.photo);
            }
            
        },
    })

    const [addEditProject,{data:addProjectData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_PROJECT,{
        onCompleted(data){
            
            if(!formData.projectID){
                setFormData({...formData,projectID:data.addEditProject.projectID,photo:""})
                setTab(7);
            }else{
                props.openCloseModal(false);
            }
            
		},update(cache,response) {    
            
            projectLib.updateList(cache,response,props.listVariables,formData.projectID);
            
            let notify = new Notification();
            if(formData.projectID && formData.projectID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    const openMainImage = (e) => {

        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            setMainPhoto(dataURL);
            setFormData({...formData,photo:file});
        };
        reader.readAsDataURL(file);
    }

    var error  = "";
    if(getError){
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    if(addError){
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(addError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.projectID && formData.projectID != 0 ? translate.t("EditProject") : translate.t("AddProject"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 7 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(7)}}>Položky projektu</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(2)}}>{translate.t("Images")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 3 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(3)}}>{translate.t("DevelopmentDates")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 4 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(4)}}>Videa</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 5 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(5)}}>Soubory ke stažení</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 6 ? "nav-link active" : (formData.projectID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectID)setTab(6)}}>Jak se stavělo</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        
                                        {selectedTab == 1 &&
                                            <div className="row">  

                                                

                                                <div className="col-6">

                                                    <label className="input-label">Hlavní fotka</label>

                                                    <div className="form-group input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                            <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                        </div>
                                                    </div>
                                                    {mainPhoto != "" &&
                                                        <div className="form-group main-photo-container">
                                                            
                                                            <div className="one-image">            
                                                                <img id="main-image" src={mainPhoto} />
                                                            </div>

                                                        </div>
                                                    }

                                                </div>

                                                
                                                <div className="col-6">

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="input-label">*{translate.t("Active")}</label>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active1">
                                                                        Ano
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    <label className="form-check-label" htmlFor="active2">
                                                                        Ne
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                                                            
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("ItemName")}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div> 
                                                        <div className={(formData.projectTypeID == 3 || formData.projectTypeID == 2 ? "col-6" : "col-12")}>
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("ProjectType")} </label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="projectTypeID"  value={formData.projectTypeID} onChange={(e) => projectLib.formHandle(e,formData,setFormData)}>
                                                                        <option value="0"> --- {translate.t("ChooseProjectType")} --- </option>
                                                                        {projectData && projectData.allProjectTypes.map((it,ind) => (
                                                                            <option key={ind} value={it.projectTypeID}>{it.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>                                                
                                                        </div>
                                                        {formData.projectTypeID == 3 &&
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("DateCompleted")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="dateCompleted" value={formData.dateCompleted} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div>
                                                        }
                                                        {formData.projectTypeID == 2 &&
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="input-label">*{translate.t("GoingToText")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="goingToText" value={formData.goingToText} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>                                                
                                                            </div> 
                                                        }

                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">*Krátký popis</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="perex" value={formData.perex} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>                                                
                                                        </div>
                                                    </div> 

                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Description")}</label>
                                                        <div className="form-group">
                                                            <Editor
                                                                name="text"
                                                                apiKey = 'yhab77rsku8a5e9rug2h5c179pcuebtk34qcnsa72hc62baw'
                                                                value={formData.description}
                                                                init={{
                                                                    plugins: 'link code paste emoticons',
                                                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link | blockquote | emoticons | code',
                                                                    paste_as_text: true,
                                                                    language_url : '/langs/' + translate.t("TinyMceLang") + '.js',
                                                                    language: translate.t("TinyMceLang"),
                                                                }}
                                                                onEditorChange={(content) => projectLib.formHandleEditor(content,formData,setFormData)}
                                                            />
                                                        </div>
                                                    </div>                                                
                                                </div> 

                                                <div className="col-12">
                                                    <div className="card main form-group">

                                                        <div className="card-header d-flex align-items-center">
                                                            {translate.t("BasicInformation")}
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="row">
                                                                {projectData && projectData.allInformationAttributes && projectData.allInformationAttributes.map((item,index) => {

                                                                    
                                                                    
                                                                    var checkedIS = false;
                                                                    for(let ia of formData.informationAttributes){
                                                                        if(ia.informationAttributeID == item.informationAttributeID){
                                                                            console.log(item);
                                                                            checkedIS = ia;
                                                                        }
                                                                    }

                                                                    return (
                                                                        <div key={index} className="col-12 col-sm-6 col-md-4">
                                                                            <div className="form-group">
                                                                                <label className="input-label">{item.name}</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name={"iatt-" + index} value={checkedIS.value} onChange={(e) => projectLib.setInformationAttribut(e,item.informationAttributeID,formData,setFormData)} />
                                                                                </div>
                                                                            </div>                                                
                                                                        </div>
                                                                    )
                                                                })}
                                                                <div className="col-12 col-sm-6 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="input-label">{translate.t("EnergyClass")} </label>
                                                                        <div className="form-group">
                                                                            <select className="form-control" name="energyClassID"  value={formData.energyClassID} onChange={(e) => projectLib.formHandle(e,formData,setFormData)}>
                                                                                <option value="0"> --- {translate.t("ChooseEnergyClass")} --- </option>
                                                                                {projectData && projectData.allEnergyClasses.map((it,ind) => (
                                                                                    <option key={ind} value={it.energyClassID}>{it.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>                                                
                                                                </div> 
                                                                <div className="col-12 col-sm-6 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="input-label">{translate.t("ForSaleFrom")}</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="forSaleFrom" value={formData.forSaleFrom} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                        </div>
                                                                    </div>                                                
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> 
                                                
                                                <div className="col-12">
                                                    <div className="card main">

                                                        <div className="card-header d-flex align-items-center">
                                                            {translate.t("SEO")}
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <label className="input-label">{translate.t("MetaTitle")} </label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="metaTitle" value={formData.metaTitle} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <label className="input-label">{translate.t("MetaKeywords")}</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="metaKeywords" value={formData.metaKeywords} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                                 
                                                                <div className="col-12">
                                                                    <label className="input-label">{translate.t("MetaDescription")}</label>
                                                                    <div className="input-group">
                                                                        <input className="form-control" type="text" name="metaDescription" value={formData.metaDescription} onChange={(e) => projectLib.formHandle(e,formData,setFormData)} />
                                                                        
                                                                        <div className="input-group-append">
                                                                            <span className={"input-group-text " + (formData.metaDescription.length > 160 ? 'red' : '')}>{formData.metaDescription.length}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>                            
                                                
                                                                        
                                            </div> 
                                        }

                                        {selectedTab == 2 &&
                                            <ProjectImages projectID = {formData.projectID} formData = {formData} setFormData = {setFormData} />
                                        }

                                        {selectedTab == 3 &&
                                            <DevelopmentDate projectID = {formData.projectID} />
                                        }

                                        {selectedTab == 4 &&
                                            <ProjectVideo projectID = {formData.projectID} />
                                        }

                                        {selectedTab == 5 &&
                                            <ProjectFiles projectID = {formData.projectID} formData = {formData} setFormData = {setFormData} />
                                        }

                                        {selectedTab == 6 &&
                                            <ProjectHistory projectID = {formData.projectID} />
                                        }
                                        {selectedTab == 7 &&
                                            <ProjectRealty projectID = {formData.projectID} formData={formData} setFormData = {setFormData} />
                                        }

                                    </div>

                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => projectLib.addProject(addEditProject,formData)}>{(formData.projectID && formData.projectID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditProject);