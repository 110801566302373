let graphqlServerUrl              = "https://server.spolme.cz/graphql";
let serverUrl                     = "https://server.spolme.cz";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8911/graphql";
    serverUrl                      = "http://localhost:8911";
}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
export const TINYMCE_IMAGE_URL               = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                = "Public/Files/TinyMCE";
export const ARTICLE_IMAGE_URL               = "Public/Images/Article";
export const PROJECT_IMAGE_URL               = "Public/Images/Project";
export const PROJECT_FILE_URL                = "Public/Files/Project";
export const PROJECT_HISTORY_IMAGE_URL       = "Public/Images/ProjectHistory";
export const CONTENT_MANAGER_IMAGE_URL       = "Public/Images/ContentManager";
