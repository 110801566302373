import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_DEVELOPMENT_DATE, GET_DEVELOPMENT_DATE,} from '../Queries/developmentDate';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import DevelopmentDatesLib from '../Library/developmentDate';
import DatePicker,{ registerLocale } from 'react-datepicker';

const AddEditDevelopmentDate = (props) => {

    const developmentDates = new DevelopmentDatesLib(props);

    const [formData,setFormData] = useState({
        
        projectDevelopmentDateID:props.selectedDevelopmentDateID,
        projectID: props.projectID,
        date:"",
        name:"",
        completed:0
        
    });

    const {data:branchData,loading:getLoading,error:getError} = useQuery(GET_DEVELOPMENT_DATE,{
        variables:{
            projectDevelopmentDateID: formData.projectDevelopmentDateID
        },
        fetchPolicy: 'network-only',
        onCompleted(data){

            developmentDates.getData(data,formData.projectDevelopmentDateID,formData,setFormData);
            
        },
    })

    const [addDevelopmentDate,{data:addBranchData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_DEVELOPMENT_DATE,{
        onCompleted(data){

			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            developmentDates.updateList(cache,response,props.listVariables,formData.projectDevelopmentDateID);
            
            let notify = new Notification();
            if(formData.projectDevelopmentDateID && formData.projectDevelopmentDateID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.projectDevelopmentDateID && formData.projectDevelopmentDateID != 0 ? "Upravit fázi" : "Přidat fázi")}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
 
                                    <div className="row">    
                                                                            
                                    <div className="col-3">
                                        <label className="input-label">*Ukončená fáze</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="completed" id="completed1" value="1" checked={(formData.completed == 1 ?  true : false)} onChange={(e) => developmentDates.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="completed1">
                                                    Ano
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="completed" id="completed2" value="0" checked={(formData.completed == 0 ?  true : false)} onChange={(e) => developmentDates.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="completed2">
                                                    Ne
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="input-label">*Datum</label>
                                                <div className="form-group">
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.date} onChange={date => setFormData({...formData,date: date})} />
                                                </div>
                                            </div>                                                
                                        </div> 
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">*Popis</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={(e) => developmentDates.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div> 
                       
                                    </div> 
                                        
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => developmentDates.addDevelopmentDate(addDevelopmentDate,formData)}>{(formData.projectDevelopmentDateID && formData.projectDevelopmentDateID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditDevelopmentDate);