const {GET_PROJECT_HISTORY_IMAGES} = require("../Queries/projectHistoryImage");

class ProjectHistoryImage {

    constructor(props){
        this.props = props;
    }

    updateProjectHistoryImages(cache,response,projectHistoryID){

        const { projectHistoryImages } = cache.readQuery({ query: GET_PROJECT_HISTORY_IMAGES, variables:{projectHistoryID} });
        var arr = [...response.data.uploadProjectHistoryImages,...projectHistoryImages];

        cache.writeQuery({ 
            query:GET_PROJECT_HISTORY_IMAGES,
            variables:{projectHistoryID},
            data:{
                projectHistoryImages: arr
            } 
        });

    }

    updateProjectHistoryImagesAfterDelete(cache,response,projectHistoryID){

        const { projectHistoryImages } = cache.readQuery({ query: GET_PROJECT_HISTORY_IMAGES, variables:{projectHistoryID} });
        var arr = [...projectHistoryImages];

        for(let i in response.data.deleteProjectHistoryImages){
            for(let j in arr){
                if(response.data.deleteProjectHistoryImages[i] == arr[j].projectHistoryImageID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_PROJECT_HISTORY_IMAGES,
            variables:{projectHistoryID},
            data:{
                projectHistoryImages: arr
            } 
        });
    }

    setDeleteImage(e,projectHistoryImageID,projectHistoryImagesID,setProjectHistoryImages){

        if(e.target.checked){
            setProjectHistoryImages([projectHistoryImageID,...projectHistoryImagesID]);
        }else{
            
            var arr = [...projectHistoryImagesID];
            for(let i in arr){
                if(arr[i] == projectHistoryImageID)arr.splice(i,1);
            }

            setProjectHistoryImages(arr);
        }

    }

    setImages(e,projectHistoryImageID,formData,setFormData){

        var name  = e.target.name;
        var value = e.target.value;

        if(formData.images.length == 0){

            var obj = {projectHistoryImageID:projectHistoryImageID}
            obj[name] = value;

            setFormData({...formData,images:[obj]});

        }else{

            var arr = [];
            var checked = false;

            for(var i in formData.images){
                if(formData.images[i].projectHistoryImageID == projectHistoryImageID){

                    checked = true;
                    arr = [...formData.images];

                    arr[i][name] = value;

                    setFormData({...formData,images:arr});
                }
            }
            if(!checked){
                var obj = {projectHistoryImageID:projectHistoryImageID}
                obj[name] = value;

                setFormData({...formData,images:[obj,...formData.images]});
            }
        }

    }

}

export default ProjectHistoryImage;