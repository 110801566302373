/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_ADMINUSER,GET_ADMINUSERS} from '../Queries/adminUser';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
const moment             = require("moment");

class AdminUser {

    constructor(props){
        this.props = props;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addAdminUser funkce z apollo, pomocí které se posílají data na server
     */

    addAdminUser = async (addAdminUser,formData,callback = null) => {

        let notify = new Notification();   
        
        if(typeof formData.roleID != "undefined" && formData.roleID != 0){                

                addAdminUser({
                    variables:{
                        addEditData:{
                            adminUserID:formData.adminUserID,
                            name:formData.name,
                            surname:formData.surname,
                            email:formData.email,
                            roleID:formData.roleID,
                            password:formData.password,
                            lang:formData.lang
                        },
                    }
                }).then(responseData => {
                    if(callback){
                        callback(true,responseData);
                    }
                }, errorData =>{
                    if(callback){
                        callback(false,errorData);
                    }
                });
        }else{
            notify.setNotification(null,translate.t("RoleNotFilled"),false,true,this.props.client);
        }
               
    }
    

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allAdminUsers } = cache.readQuery({ query: GET_ADMINUSERS, variables});
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditAdminUser,...allAdminUsers]   ;
            
            cache.writeQuery({ 
                query:GET_ADMINUSERS,
                data:{
                    allAdminUsers: arr
                }, 
                variables
            });

        }else{

            var arr = [...allAdminUsers];
            
            arr.forEach((item,index) => {
                
                if(item.adminUserID == response.data.addEditAdminUser.adminUserID){
                    arr[index].adminUserID        = response.data.addEditAdminUser.adminUserID;
                    arr[index].name               = response.data.addEditAdminUser.name;
                    arr[index].surname            = response.data.addEditAdminUser.surname;
                    arr[index].roleID             = response.data.addEditAdminUser.roleID;
                    arr[index].roleTitle          = response.data.addEditAdminUser.roleTitle;
                }

            });
            cache.writeQuery({ 
                query:GET_ADMINUSERS,
                data:{
                    allAdminUsers: arr
                }, 
                variables 
            });
        }
    }


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteAdminUsers.split(",");

        const { allAdminUsers } = cache.readQuery({ query: GET_ADMINUSERS ,variables});
        var arr = [...allAdminUsers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.adminUserID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_ADMINUSERS,
            variables,
            data:{
                allAdminUsers: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.adminUser === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    
}

export default AdminUser;