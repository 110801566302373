/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';

import AllArticles from '../Modules/Article/Components/AllArticles.js';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Link from '../Modules/Content/Components/Link';
import AdminUsers from '../Modules/AdminUser/Components/AdminUsers';

import DeliveryAreas from '../Modules/Driver/Components/DeliveryAreas';

import Projects from '../Modules/Project/Components/Projects';
import RealtyAttributes from '../Modules/Project/Components/RealtyAttributes';
import InformationAttributes from '../Modules/Project/Components/InformationAttributes';
import ProjectHomepage from '../Modules/Project/Components/ProjectHomepage';

class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/content/link' component={Link}></Route>
					<Route exact path='/users' component={AdminUsers}></Route>
					<Route exact path='/projects' component={Projects}></Route>
					<Route exact path='/homepage-projects' component={ProjectHomepage}></Route>
					<Route exact path='/projects/realty-attributes' component={RealtyAttributes}></Route>
					<Route exact path='/projects/information-attributes' component={InformationAttributes}></Route>
					<Route exact path='/drivers/delivery-areas' component={DeliveryAreas}></Route>
					<Route exact path='/content/article' component={AllArticles}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					
				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
