const {GET_PROJECT_FILES} = require("../Queries/projectFile");

class ProjectFile {

    constructor(props){
        this.props = props;
    }

    updateProjectFiles(cache,response,projectID){

        const { projectFiles } = cache.readQuery({ query: GET_PROJECT_FILES, variables:{projectID} });
        var arr = [...response.data.uploadProjectFiles,...projectFiles];

        cache.writeQuery({ 
            query:GET_PROJECT_FILES,
            variables:{projectID},
            data:{
                projectFiles: arr
            } 
        });

    }

    updateProjectFilesAfterDelete(cache,response,projectID){

        const { projectFiles } = cache.readQuery({ query: GET_PROJECT_FILES, variables:{projectID} });
        var arr = [...projectFiles];

        for(let i in response.data.deleteProjectFiles){
            for(let j in arr){
                if(response.data.deleteProjectFiles[i] == arr[j].projectFileID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_PROJECT_FILES,
            variables:{projectID},
            data:{
                projectFiles: arr
            } 
        });
    }

    setDeleteFile(e,projectFileID,projectFilesID,setProjectFiles){

        if(e.target.checked){
            setProjectFiles([projectFileID,...projectFilesID]);
        }else{
            
            var arr = [...projectFilesID];
            for(let i in arr){
                if(arr[i] == projectFileID)arr.splice(i,1);
            }

            setProjectFiles(arr);
        }

    }

    setFiles(e,projectFileID,formData,setFormData){

        var name  = e.target.name;
        var value = e.target.value;

        if(formData.files.length == 0){

            var obj = {projectFileID:projectFileID}
            obj[name] = value;

            setFormData({...formData,files:[obj]});

        }else{

            var arr = [];
            var checked = false;

            for(var i in formData.files){
                if(formData.files[i].projectFileID == projectFileID){

                    checked = true;
                    arr = [...formData.files];

                    arr[i][name] = value;

                    setFormData({...formData,files:arr});
                }
            }
            if(!checked){
                var obj = {projectFileID:projectFileID}
                obj[name] = value;

                setFormData({...formData,files:[obj,...formData.files]});
            }
        }

    }

}

export default ProjectFile;