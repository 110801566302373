/**
 * soubor se seznamem veškerých graphql dotazů pro obrázky
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých obrázku jídla
 */
export const GET_PROJECT_HISTORY_IMAGES = gql`
    query ProjectHistoryImages($projectHistoryID: ID!){
        projectHistoryImages(projectHistoryID:$projectHistoryID){
            projectHistoryImageID
            name
        }

    }
`;

/**
 * vymazání obrázku jídla
 */
export const DELETE_PROJECT_HISTORY_IMAGES = gql`
    mutation deleteProjectHistoryImages($projectHistoryImagesIDs:[ID]!,$projectHistoryID:ID!) {
        deleteProjectHistoryImages(projectHistoryImagesIDs:$projectHistoryImagesIDs,projectHistoryID:$projectHistoryID)
    }
`;


/**
 * Upload obrázku pro jídlo
 */
export const UPLOAD_PROJECT_HISTORY_IMAGES = gql`
  mutation uploadProjectHistoryImages($files: Upload!,$projectHistoryID:ID!) {
    uploadProjectHistoryImages(files: $files,projectHistoryID:$projectHistoryID){
        projectHistoryImageID
        name
    } 
  }
`; 


