/**
 * soubor se seznamem veškerých graphql dotazů pro obrázky
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých obrázku jídla
 */
export const GET_PROJECT_FILES = gql`
    query ProjectFiles($projectID: ID!){
        projectFiles(projectID:$projectID){
            projectFileID
            name
            file
            type
        }

    }
`;

/**
 * vymazání obrázku jídla
 */
export const DELETE_PROJECT_FILES = gql`
    mutation deleteProjectFiles($projectFilesIDs:[ID]!,$projectID:ID!) {
        deleteProjectFiles(projectFilesIDs:$projectFilesIDs,projectID:$projectID)
    }
`;


/**
 * Upload obrázku pro jídlo
 */
export const UPLOAD_PROJECT_FILES = gql`
  mutation uploadProjectFiles($files: Upload!,$projectID:ID!) {
    uploadProjectFiles(files: $files,projectID:$projectID){
        projectFileID
        name
        file
        type
    } 
  }
`; 


