import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {GET_PROJECT_HOMEPAGES,DELETE_PROJECT_HOMEPAGES,UPDATE_PROJECT_HOMEPAGE_PRIORITY,ADD_EDIT_PROJECT_HOMEPAGE} from '../Queries/projectHomepage';
import {SEACRH_PROJECTS} from '../Queries/project';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SearchInput from '../../../GlobalComponents/SearchInput';

const ProjectHomepage = (props) => {

    const listVariables = {
        lang:"cz"
    }

    const [selectedProjectHomepages,setSelectedProjectHomepages] = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
    const [showSearchedProjects,setShowSearchedProjects] = useState(false);
    const [searchedProjects,setSearchedProjects] = useState([]);
    
    const {loading, error:getError, data:projectHomepagesData, refetch}   = useQuery(GET_PROJECT_HOMEPAGES,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });

    const [addEditProjectHomepage, { data:dddData,loading: addLoading, error: addError }] = useMutation(ADD_EDIT_PROJECT_HOMEPAGE,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [deleteProjectHomepage, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_PROJECT_HOMEPAGES,{	
		update(cache,response) {            
            
            refetch();

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const [updateProjectHomepagePriority,{data:updateData,loading:updateLoading,error:updateError}] = useMutation(UPDATE_PROJECT_HOMEPAGE_PRIORITY,{
        update(){
            refetch();
        }
    })

    const showDelNotifi = () => {
        if(selectedProjectHomepages.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delProjectHomepage = (action) => {

        if(action){

            let projectHomepageIDs = selectedProjectHomepages.join(",");
            deleteProjectHomepage({variables:{projectHomepageIDs}});
        }

        setShowDeleteNotifi(false);
    }

    const initiateDeleteProjectHomepage = (e,projectHomepageID) => {

        var checked = e.target.checked;
        var arr = [...selectedProjectHomepages];

        if(!checked){
            for(let i in selectedProjectHomepages){
                if(selectedProjectHomepages[i] == projectHomepageID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [projectHomepageID,...arr];
        }

        setSelectedProjectHomepages(arr);
  
    }

    const onDragEnd = (result,updateProjectHomepagePriority) => {

        const IDs = result.draggableId.split("-");

        updateProjectHomepagePriority({
            variables:{
                projectHomepageID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    const searchProject = async (value) =>{        

        var data = await props.client.query({ 
            query: SEACRH_PROJECTS,
            variables:{
                text:value
            }, 
            fetchPolicy: 'network-only'
        });

        setShowSearchedProjects(true);
        setSearchedProjects(data.data.searchProjects);
            
    }

    if(getError || deleteError || updateError || addError){

        var error = getError ? getError : (deleteError ? deleteError : updateError);
        if(addError)error = addError;

        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return (
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">

                            <div style={{flex:1}}>
                                <SearchInput className="form-control" placeholder="Vyhledat produkt" onChange = {(value) => searchProject(value)} />
                            </div>
                            
                            <button className="btn btn-danger btn-thiner ml-5" onClick={() => showDelNotifi()}>Smazat označené</button>
                            
                        </div>
                        <div className="card-body">

                            {showSearchedProjects && searchedProjects &&

                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="data-list">                                                                

                                                {searchedProjects && searchedProjects.map((item,index) => (

                                                    <div key={index} className="data-list-item-content">
                                                        <div>
                                                            {item.name}
                                                        </div>
                                                        <div className="text-right">
                                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addEditProjectHomepage({variables:{projectID: item.projectID}})} >Přidat</button>
                                                        </div>
                                                    </div>

                                                ))}

                                                {searchedProjects && searchedProjects.length == 0 &&
                                                    <NoItems text={"Nebyl nalezen žádný vyhovující projekt."} />
                                                } 

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        
                            }

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Projekt</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        
                                        {projectHomepagesData && projectHomepagesData.allProjectHomepages && projectHomepagesData.allProjectHomepages.length > 0 &&

                                            <DragDropContext onDragEnd={(result) => onDragEnd(result,updateProjectHomepagePriority)}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {projectHomepagesData && projectHomepagesData.allProjectHomepages && projectHomepagesData.allProjectHomepages.map((item,index) => {
                                                                
                                                                var checked = false;
                                                                for(let i in selectedProjectHomepages){
                                                                    if(selectedProjectHomepages[i] == item.projectHomepageID)checked = true;
                                                                }

                                                                return (
                                                                    <Draggable key={index} draggableId={item.projectHomepageID} index={index}>
                                                                        {(provided, snapshot) => (
                                                                        <div className="data-list-item" 
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className="data-list-item-content categories">
                                                                                <div className="cross" style={{paddingLeft:10}}>
                                                                                    <img className="move-icon" src={move} />
                                                                                    {item.project.name}
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    
                                                                                    <input className="delete-checked" type="checkbox" name="deleteAthlete[]" checked={checked} onChange = {(e) => initiateDeleteProjectHomepage(e,item.projectHomepageID)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                                
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        }
                                    </div>
                                    {!loading ? 
                                        <Fragment>
                                            {projectHomepagesData &&  ((projectHomepagesData.allProjectHomepages && projectHomepagesData.allProjectHomepages.length == 0) || !projectHomepagesData.allProjectHomepages)&&
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            }
                                        </Fragment>
                                        :                                    
                                        <Loading />                                    
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delProjectHomepage} /> }	
        </div>
    )

}

export default withApollo(ProjectHomepage);