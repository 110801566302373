const {GET_PROJECT_IMAGES} = require("../Queries/projectImage");

class ProjectImage {

    constructor(props){
        this.props = props;
    }

    updateProjectImages(cache,response,projectID){

        const { projectImages } = cache.readQuery({ query: GET_PROJECT_IMAGES, variables:{projectID} });
        var arr = [...response.data.uploadProjectImages,...projectImages];

        cache.writeQuery({ 
            query:GET_PROJECT_IMAGES,
            variables:{projectID},
            data:{
                projectImages: arr
            } 
        });

    }

    updateProjectImagesAfterDelete(cache,response,projectID){

        const { projectImages } = cache.readQuery({ query: GET_PROJECT_IMAGES, variables:{projectID} });
        var arr = [...projectImages];

        for(let i in response.data.deleteProjectImages){
            for(let j in arr){
                if(response.data.deleteProjectImages[i] == arr[j].projectImageID){
                    arr.splice(j,1);
                }
            }
        }

        cache.writeQuery({ 
            query:GET_PROJECT_IMAGES,
            variables:{projectID},
            data:{
                projectImages: arr
            } 
        });
    }

    setDeleteImage(e,projectImageID,projectImagesID,setProjectImages){

        if(e.target.checked){
            setProjectImages([projectImageID,...projectImagesID]);
        }else{
            
            var arr = [...projectImagesID];
            for(let i in arr){
                if(arr[i] == projectImageID)arr.splice(i,1);
            }

            setProjectImages(arr);
        }

    }

    setImages(e,projectImageID,formData,setFormData){

        var name  = e.target.name;
        var value = e.target.value;

        if(formData.images.length == 0){

            var obj = {projectImageID:projectImageID}
            obj[name] = value;

            setFormData({...formData,images:[obj]});

        }else{

            var arr = [];
            var checked = false;

            for(var i in formData.images){
                if(formData.images[i].projectImageID == projectImageID){

                    checked = true;
                    arr = [...formData.images];

                    arr[i][name] = value;

                    setFormData({...formData,images:arr});
                }
            }
            if(!checked){
                var obj = {projectImageID:projectImageID}
                obj[name] = value;

                setFormData({...formData,images:[obj,...formData.images]});
            }
        }

    }

}

export default ProjectImage;