import {GET_ALL_PROJECT_HISTORY} from '../Queries/projectHistory';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import moment from 'moment';

class ProjectHistory {

    constructor(props){
        this.props = props;
    }

    addProjectHistory(addProjectHistory,formData){

        if(formData.description != ""){
  
            if(formData.name != ""){
  
                if(formData.photo != "" || formData.projectHistoryID != 0){
  
                    addProjectHistory({
                        variables:{
                            projectHistoryID: formData.projectHistoryID,
                            projectID:        formData.projectID,
                            name:             formData.name,
                            description:      formData.description,
                            photo:            formData.photo,
                
                        }
                    }); 
        
                }else{
                    let notify = new Notification();
                    notify.setNotification(null,"Nevybrali jste forku.",false,true,this.props.client);
                } 
    
            }else{
                let notify = new Notification();
                notify.setNotification(null,"Nevyplnili jste název.",false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,"Nevyplnili jste popis.",false,true,this.props.client);
        }

    }

    getData(data,projectHistoryID,formData,setFormData){

        if(projectHistoryID != 0){
            
            setFormData({...formData,
                projectID:                  formData.projectID,
                date:                       moment(data.projectHistory.date).toDate(),
                name:                       data.projectHistory.name,
                description:                data.projectHistory.description
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,projectHistoryID){

        const { allProjectHistory } = cache.readQuery({ query: GET_ALL_PROJECT_HISTORY ,variables});       
        
        if(projectHistoryID && projectHistoryID != 0){

            var arr = [...allProjectHistory];

            arr.forEach((item,index) => {
                
                if(item.projectHistoryID == response.data.addEditProjectHistory.projectHistoryID){
                    arr[index] = response.data.addEditProjectHistory;
                }

            });

            cache.writeQuery({ 
                query:GET_ALL_PROJECT_HISTORY,
                variables,
                data:{
                    allProjectHistory: arr
                } 
            });
        

        }else{
            
            var arr = [...allProjectHistory,response.data.addEditProjectHistory];

            cache.writeQuery({ 
                query:GET_ALL_PROJECT_HISTORY,
                variables,
                data:{
                    allProjectHistory: arr
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteProjectHistory.split(",");

        const { allProjectHistory,projectHistoryListCount } = cache.readQuery({ query: GET_ALL_PROJECT_HISTORY ,variables});
        var arr = [...allProjectHistory];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.projectHistoryID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_ALL_PROJECT_HISTORY,
            variables,
            data:{
                allProjectHistory: [...arr],
                projectHistoryListCount :projectHistoryListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default ProjectHistory;