import {GET_PROJECTS} from '../Queries/project';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';

class Project {

    constructor(props){
        this.props = props;
    }

    addProject(addEditProject,formData){

        if(formData.name != ""){

            if(formData.description != ""){

                if(formData.perex != ""){

                    if(formData.projectTypeID != 0){
        
                                            
                        if((formData.projectTypeID == 2 && formData.goingToText != "") || formData.projectTypeID != 2){
        
                                            
                            if((formData.projectTypeID == 3 && formData.dateCompleted != "") || formData.projectTypeID != 3){
        
                                if(formData.photo != "" || formData.projectID != 0){

                                    formData.active = parseInt(formData.active);
                                            
                                    addEditProject({
                                        variables:formData
                                    }); 
    
                                }else{
                                    let notify = new Notification();
                                    notify.setNotification(null,"Nevybrali jste fotku.",false,true,this.props.client);
                                }
                    
                
                            }else{
                                let notify = new Notification();
                                notify.setNotification(null,translate.t("InvalidDateCompleted"),false,true,this.props.client);
                            }
                
            
                        }else{
                            let notify = new Notification();
                            notify.setNotification(null,translate.t("InvalidGoingToText"),false,true,this.props.client);
                        }
            
        
                    }else{
                        let notify = new Notification();
                        notify.setNotification(null,translate.t("InvalidProjectType"),false,true,this.props.client);
                    }
        
                }else{
                    let notify = new Notification();
                    notify.setNotification(null,"Nevyplnili jste krátký popis",false,true,this.props.client);
                }
    
            }else{
                let notify = new Notification();
                notify.setNotification(null,translate.t("InvalidProjectDescription"),false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidProjectName"),false,true,this.props.client);
        }

    }

    getData(data,projectID,formData,setFormData){

        console.log(data);

        if(projectID != 0){

            if(data.project.informationAttributes.length > 0){
                for(let val of data.project.informationAttributes){
                    delete(val.__typename);
                }
            }
            
            setFormData({...formData,
                projectTypeID:          data.project.projectTypeID,
                name:                   data.project.name,
                mapZoom:                data.project.mapZoom,
                mapLng:                 data.project.mapLng,
                mapLat:                 data.project.mapLat,
                description:            data.project.description,
                perex:                  data.project.perex,
                active:                 data.project.active, 
                metaTitle:              data.project.metaTitle, 
                metaDescription:        data.project.metaDescription, 
                metaKeywords:           data.project.metaKeywords, 
                dateCompleted:          data.project.dateCompleted,
                goingToText:            data.project.goingToText,
                forSaleFrom:            data.project.forSaleFrom,
                energyClassID:          data.project.energyClassID,
                informationAttributes:  data.project.informationAttributes
            });
        }
    }

    setInformationAttribut(e,informationAttributeID,formData,setFormData){

        const value = e.target.value;

        var arr = [...formData.informationAttributes];

        var checked = false;
        for(let i in arr){
            if(arr[i].informationAttributeID == informationAttributeID){
                checked = true;
                arr[i] = {...arr[i],value}
            }
        }

        if(!checked)arr.push({
            informationAttributeID,
            value
        });

        setFormData({...formData,informationAttributes:arr});

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,projectID){

        const { allProjects,projectListCount } = cache.readQuery({ query: GET_PROJECTS ,variables});

        
        if(projectID && projectID != 0){

            var arr = [...allProjects];

            arr.forEach((item,index) => {
                
                if(item.projectID == response.data.addEditProject.projectID){
                    arr[index] = response.data.addEditProject;
                }

            });

            cache.writeQuery({ 
                query:GET_PROJECTS,
                variables,
                data:{
                    allProjects: arr,
                    projectListCount :projectListCount
                } 
            });

        }else{
            var arr = [response.data.addEditProject,...allProjects];

            cache.writeQuery({ 
                query:GET_PROJECTS,
                variables,
                data:{
                    allProjects: arr,
                    projectListCount :projectListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteProjects.split(",");

        const { allProjects,projectListCount } = cache.readQuery({ query: GET_PROJECTS ,variables});
        var arr = [...allProjects];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.projectID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_PROJECTS,
            variables,
            data:{
                allProjects: [...arr],
                projectListCount :projectListCount - resp.length
            } 
        });

    }

    formHandleEditor(content,formData,setFormData){
        
        setFormData({...formData,description: content});
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default Project;