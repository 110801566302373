/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_PROJECT_REALTY = gql`
    mutation AddEditProjectRealty($allProjectRealtys:[AllProjectRealtyInput],$projectID:ID){
        addEditProjectRealty(allProjectRealtys:$allProjectRealtys,projectID:$projectID){
            projectRealtyID
            projectID
            stateID
            active
            areaPath
            priority
            color
            attributes{
                projectRealtyAttributeID
                projectRealtyID
                realtyAttributeID
                value
                priority
            }
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_PROJECT_REALTYS = gql`
    query AllProjectRealtys($projectID:ID){
        allProjectRealtys(projectID:$projectID){            
            projectRealtyID
            projectID
            stateID
            active
            areaPath
            priority
            color
            attributes{
                projectRealtyAttributeID
                projectRealtyID
                realtyAttributeID
                value
                priority
            }
        }
        allRealtyAttributes{
            realtyAttributeID
            name
        }
        allStates{
            name
            stateID
        }
    }
`;

/**
 * Smazání doručovací oblasti
 */

export const DELETE_PROJECT_REALTY = gql`
    mutation deleteProjectRealty($projectRealtyID:ID!) {
        deleteProjectRealty(projectRealtyID:$projectRealtyID)
    }
`;

