/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_PROJECT_HOMEPAGE = gql`
    mutation AddEditProjectHomepage($projectID:ID){
        addEditProjectHomepage(projectID:$projectID){
            projectHomepageID
            projectID
            project{
                name
            }
        }
    }
`;


/**
 *  Seznam veškerých kategorií
 */
export const GET_PROJECT_HOMEPAGES = gql`
    query AllProjectHomepages{
        allProjectHomepages{    
            projectHomepageID        
            projectID
            project{
                name
            } 
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_PROJECT_HOMEPAGES = gql`
    mutation deleteProjectHomepages($projectHomepageIDs:ID!) {
        deleteProjectHomepages(projectHomepageIDs:$projectHomepageIDs)
        reindexProjectHomepagePriority
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_PROJECT_HOMEPAGE_PRIORITY = gql`
    mutation updateProjectHomepagePriority($projectHomepageID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateProjectHomepagePriority(projectHomepageID:$projectHomepageID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;