/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_PROJECT = gql`
    mutation AddEditProject(
        $projectID:ID,
        $projectTypeID:ID,
        $energyClassID:ID,
        $active:Int,
        $name:String,
        $niceName:String,
        $photo:Upload,
        $description:String,
        $perex:String,
        $dateCompleted:String,
        $goingToText:String,
        $forSaleFrom:String,
        $mapZoom:Int,
        $mapLat:Float,
        $mapLng:Float,
        $metaTitle:String,
        $metaKeywords:String,
        $metaDescription:String,
        $informationAttributes:[InformationAttributes],
        $images:[ProjectImageUpdate],
        $files:[ProjectFileUpdate]
    ){
        addEditProject(
            projectID:$projectID,
            projectTypeID:$projectTypeID,
            energyClassID:$energyClassID,
            active:$active,
            name:$name,
            niceName:$niceName,
            photo:$photo,
            description:$description,
            perex:$perex,
            dateCompleted:$dateCompleted,
            goingToText:$goingToText,
            forSaleFrom:$forSaleFrom,
            mapZoom:$mapZoom,
            mapLat:$mapLat,
            mapLng:$mapLng,
            metaTitle:$metaTitle,
            metaKeywords:$metaKeywords,
            metaDescription:$metaDescription,
            informationAttributes:$informationAttributes,
            images:$images,
            files:$files
        ){
            projectID
            projectTypeID
            active
            name
            photo
            type{
                name
            }   
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_PROJECTS = gql`
    query AllProjects($offset:Int,$limit:Int){
        allProjects(offset:$offset,limit:$limit){            
            projectID
            projectTypeID
            active
            name
            type{
                name
            }      
        }
        projectListCount
    }
`;

export const SEACRH_PROJECTS = gql`
    query SearchProjects($text:String){
        searchProjects(text:$text){            
            projectID
            name     
        }
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_PROJECT = gql`
    query Project($projectID:ID){
        project(projectID:$projectID){            
            projectID
            projectTypeID
            energyClassID
            active
            name    
            photo
            description
            perex
            dateCompleted
            goingToText
            forSaleFrom
            mapZoom
            mapLng
            mapLat
            metaTitle
            metaKeywords
            metaDescription
            informationAttributes{
                informationAttributeID
                value
            }
        }
        allEnergyClasses{
            energyClassID
            name
        }
        allProjectTypes{
            projectTypeID
            name
        }
        allInformationAttributes{
            informationAttributeID
            name
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_PROJECTS = gql`
    mutation deleteProjects($projectIDs:ID!) {
        deleteProjects(projectIDs:$projectIDs)
    }
`;



