/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_REALTY_ATTRIBUTE = gql`
    mutation AddEditRealtyAttribute($realtyAttributeID:ID,$name:String){
        addEditRealtyAttribute(realtyAttributeID:$realtyAttributeID,name:$name){
            realtyAttributeID
            name
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_REALTY_ATTRIBUTE = gql`
    query RealtyAttribute($realtyAttributeID: ID!){
        realtyAttribute(realtyAttributeID:$realtyAttributeID){
            realtyAttributeID
            name     
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_REALTY_ATTRIBUTES = gql`
    query AllRealtyAttributes{
        allRealtyAttributes{            
            realtyAttributeID
            name   
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_REALTY_ATTRIBUTES = gql`
    mutation deleteRealtyAttributes($realtyAttributeIDs:ID!) {
        deleteRealtyAttributes(realtyAttributeIDs:$realtyAttributeIDs)
    }
`;