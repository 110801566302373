import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {GET_PROJECT_HISTORY_IMAGES,DELETE_PROJECT_HISTORY_IMAGES,UPLOAD_PROJECT_HISTORY_IMAGES} from '../Queries/projectHistoryImage';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {SERVER_URL,PROJECT_HISTORY_IMAGE_URL} from '../../../Config/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import ProjectHistoryImageLib from '../Library/projectHistoryImage';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';

const ProjectHistoryImages = (props) => {

    const projectHistoryPhotoLib = new ProjectHistoryImageLib(props);

    const [showDeleteInfo,setDeleteInfo] = useState(false);
    const [projectHistoryImagesID,setProjectHistoryImages] = useState([]);

    var {data,loading,error:getError} = useQuery(GET_PROJECT_HISTORY_IMAGES,{
        variables:{
            projectHistoryID:props.projectHistoryID
        }
    })

    const [deleteProjectHistoryImages,{loading:deleteLoading}] = useMutation(DELETE_PROJECT_HISTORY_IMAGES,{
        variables:{
            projectHistoryID: props.projectHistoryID
        },
        update(cache,response) {
            projectHistoryPhotoLib.updateProjectHistoryImagesAfterDelete(cache,response,props.projectHistoryID);
        }
    })
    
    const [uploadProjectHistoryImages,{error:uploadError,loading:uploadLoading}] = useMutation(UPLOAD_PROJECT_HISTORY_IMAGES,{
        update(cache,response) {
            projectHistoryPhotoLib.updateProjectHistoryImages(cache,response,props.projectHistoryID);
        }
    });

    const showDelNotifi = () => {
        if(projectHistoryImagesID.length > 0){
            setDeleteInfo(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delProjectHistoryImage = (action) => {

        if(action){

            deleteProjectHistoryImages({variables:{projectHistoryImagesIDs:projectHistoryImagesID}});
        }

        setDeleteInfo(false);
    }

    var error = "";
    if(getError || uploadError){

        var err = getError || uploadError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(err);
    }

    if(deleteLoading)loading = deleteLoading;
    if(uploadLoading)loading = uploadLoading;

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    <div className="form-group input-group">
                        <div className="custom-file">
                            
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                required
                                multiple
                                onChange={
                                    ({ target: { validity, files: files } }) => {
                                        validity.valid && uploadProjectHistoryImages({ variables: { files:files, projectHistoryID: props.projectHistoryID } });
                                    }   
                                }
                            />
                            <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhotos")}</label>
                        </div>
                        <div className="input-group-append">
                            <button className="btn btn-danger btn-delete-files" type="button" onClick={() => showDelNotifi()}>{translate.t("DeleteSelectedPhotos")}</button>
                        </div>
                    </div>

                    {loading ? 

                        <Loading />
                
                        :
                
                        <div className="row">
                    
                            {data.projectHistoryImages && data.projectHistoryImages.map((item,index) => {

                                                        
                                return(
                                    <div key={index} className="col-2 form-group">
                                        <div className="image-item">
                                            <div className="menu-item">
                                                <input type="checkbox" name="delete" onChange={(e) => projectHistoryPhotoLib.setDeleteImage(e,item.projectHistoryImageID,projectHistoryImagesID,setProjectHistoryImages)} />
                                            </div>
                                            
                                            <img src={SERVER_URL + "/" + PROJECT_HISTORY_IMAGE_URL + "/ph-" + props.projectHistoryID + "/mala_" + item.name} />
  
                                        </div>
                                    </div>
                                )
                            })}         
                    
                        </div>

                    }
                
                    {showDeleteInfo && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané obrázky ?'} callback={delProjectHistoryImage} />}
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(ProjectHistoryImages);









