import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import {GET_REALTY_ATTRIBUTES} from '../Queries/realtyAttribute';

class RealtyAttribute {

    constructor(props){
        this.props = props;
    }

    addRealtyAttribute(addRealtyAttribute,formData){

        if(formData.name != ""){
                       
            addRealtyAttribute({
                variables:formData
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("InvalidRealtyAttributeName"),false,true,this.props.client);
        }
                              
    }

    getData(data,realtyAttributeID,formData,setFormData){

        if(realtyAttributeID != 0){
            
            setFormData({...formData,
                
                name: data.realtyAttribute.name,
                realtyAttributeID: data.realtyAttribute.realtyAttributeID
                
            });
        }

    }

    updateAfterDelete(cache, response){

        var resp = response.data.deleteRealtyAttributes.split(",");

        const { allRealtyAttributes } = cache.readQuery({ query: GET_REALTY_ATTRIBUTES});
        var arr = [...allRealtyAttributes];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.realtyAttributeID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_REALTY_ATTRIBUTES,
            data:{
                allRealtyAttributes: [...arr],
            } 
        });

    }

    updateList(cache, response,realtyAttributeID){

        const { allRealtyAttributes } = cache.readQuery({ query: GET_REALTY_ATTRIBUTES});
  
        if(realtyAttributeID && realtyAttributeID != 0){

            var arr = [...allRealtyAttributes];

            arr.forEach((item,index) => {
                
                if(item.realtyAttributeID == response.data.addEditRealtyAttribute.realtyAttributeID){
                    arr[index] = response.data.addEditRealtyAttribute;
                }

            });

            cache.writeQuery({ 
                query:GET_REALTY_ATTRIBUTES,
                data:{
                    allRealtyAttributes: arr
                } 
            });

        }else{
            var arr = [response.data.addEditRealtyAttribute,...allRealtyAttributes];

            cache.writeQuery({ 
                query:GET_REALTY_ATTRIBUTES,
                data:{
                    allRealtyAttributes: arr
                } 
            });
        }

    }


    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default RealtyAttribute;