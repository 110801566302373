import React,{ Component, Fragment, useState,useEffect } from 'react';
import {withApollo,useMutation,useQuery} from 'react-apollo';
import translate from '../../Translations/index';
import ProjectRealtyLib from '../Library/projectRealty';
import { SketchPicker  } from 'react-color';
import {ADD_EDIT_PROJECT_REALTY,GET_PROJECT_REALTYS,DELETE_PROJECT_REALTY} from '../Queries/projectRealty';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import moment from 'moment';

var wHeight = window.innerHeight - 15;
var gogleMap = null;
var polygon = null;
var polygonList = [];
var listener;
var ApiKey = "";

const INITIAL_FORM_DATA = {
    color:"#49B01A",
    stateID:1,
    priority:1,
    active:1
}

const ProjectRealty = (props) => {

    const projectRealtyLib = new ProjectRealtyLib(props);

    const [mapIsReaddy,setMapIsReady]              = useState(false);
    const [addArea,setAddArea]                     = useState(false);
    const [showColorPicker,setShowColorPicker]     = useState(false);
    const [allProjectRealtys,setAllProjectRealtys] = useState([]);

    const [formData,setFormData] = useState(INITIAL_FORM_DATA);
    const [selectedProjectRealtyID,setSelectedProjectRealtyID] = useState(null);
    const [newProjectRealtyID,setNewProjectRealtyID] = useState(null);

    useEffect(()=>{

        if(newProjectRealtyID){

            var isUpdating = !!polygon.polyData;
            polygon.polyData = {
                projectRealtyID : newProjectRealtyID
            }
           
            if(!isUpdating){
                polygonList.push(polygon);
                polygon.addListener('click', polygonClick);
            }else{
                window.google.maps.event.clearInstanceListeners(polygon);
                polygon.addListener('click', polygonClick);
            }

            polygon = null;
            setNewProjectRealtyID(null);

        }

    },[newProjectRealtyID])

    const {data,error,loading:getLoading,refetch} = useQuery(GET_PROJECT_REALTYS,{
        variables:{
            projectID:props.projectID
        },
        fetchPolicy:"network-only",
        onCompleted(data){

            gogleMap = false;
            clearProjectRealtys(data.allProjectRealtys);
        }
    });

    var [addEditProjectRealty,{loading}] = useMutation(ADD_EDIT_PROJECT_REALTY,{
        onCompleted(data){

            var isUpdating = !!polygon.polyData;

            console.log(isUpdating);

            polygon.setOptions({editable:false});
            if(isUpdating){

                clearProjectRealtys(data.addEditProjectRealty);
                setNewProjectRealtyID(selectedProjectRealtyID);

            }else{

                var projectRealtyID = 0;
                for(let val of data.addEditProjectRealty){
                    var check = false;
                    for(let val2 of allProjectRealtys){
                        if(val2.projectRealtyID == val.projectRealtyID){
                            check = true;
                            break;
                        }
                    }

                    if(!check){
                        projectRealtyID = val.projectRealtyID;
                        break;
                    }
                }

                clearProjectRealtys(data.addEditProjectRealty);
                if(projectRealtyID != 0)setNewProjectRealtyID(projectRealtyID);

            }

            setAddArea(false);
            setFormData(INITIAL_FORM_DATA);
            setSelectedProjectRealtyID(null);
            
            window.google.maps.event.removeListener(listener);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullySaved"),true,false,props.client);

            setClicablePolygons(true);
            

        },onError(err){

            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });

    var [deleteProjectRealty,{loading:deleteLoading}] = useMutation(DELETE_PROJECT_REALTY,{
        onCompleted(data){

            for(let i in polygonList){
                if(polygonList[i].id == polygon.id){
                    polygonList.splice(i,1);
                }
            }

            window.google.maps.event.clearInstanceListeners(polygon);

            polygon.setMap(null);
            polygon = null;  

            setAddArea(false);
            setFormData(INITIAL_FORM_DATA);
            setClicablePolygons(true);
            setSelectedProjectRealtyID(null);

            window.google.maps.event.removeListener(listener);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessFullyDeleted"),true,false,props.client);

        },onError(err){

            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });

    const clearProjectRealtys = (prData) => {
        var arr = [...prData];
        for(let val of arr){
            delete(val["__typename"]);  
            if(val.attributes && val.attributes.length > 0){

                for(let att of val.attributes){
                    delete(att["__typename"]);  
                } 
            }
        } 

        setAllProjectRealtys(arr);
    }

    const addLatLng = (event,color) => {

        if(!polygon){

            polygon = new window.google.maps.Polygon({
                strokeColor: color,
                strokeOpacity: 1.0,
                clickable:false,
                strokeWeight: 3,
                fillColor: color,
                fillOpacity: 0.35,
                id: moment().format("x"),
                editable:true
            });

            polygon.setMap(gogleMap);

        }

        var path = polygon.getPath();
        path.push(event.latLng);
    
    }

    const savePolygon = () => {
        
        if(polygon){
            
            var path = polygon.getPath();
            var pathString = "";

            // Iterate over the vertices.
            for (var i =0; i < path.getLength(); i++) {
                var xy = path.getAt(i);
                if(pathString)pathString += ";";
                pathString += xy.lat() + ',' + xy.lng();
            }

            var arr = [...allProjectRealtys];
            for(let i in arr){
                if(arr[i].projectRealtyID == selectedProjectRealtyID || arr[i].projectRealtyID == 0){    
                    arr[i] = {...arr[i],
                        areaPath:pathString,
                        stateID:formData.stateID,
                        active:parseInt(formData.active),
                        color:formData.color,
                        priority:parseInt(formData.priority)
                    }
                }
            }

            addEditProjectRealty({
                variables:{
                    allProjectRealtys:arr,
                    projectID:props.projectID
                }
            });

            setAllProjectRealtys(arr);
  
        }else{

            polygon = null;  
            setAddArea(false);
            setClicablePolygons(true);
            window.google.maps.event.removeListener(listener);

        }  
 
    }

    const setClicablePolygons = (clickable) => {

        if(polygonList.length > 0){
            for(let val of polygonList){
                val.setOptions({
                    clickable:clickable
                })
            }
        }
    }

    const setEditablePolygons = (editable) => {

        if(polygonList.length > 0){
            for(let val of polygonList){
                val.setOptions({
                    editable:editable
                })
            }
        }
    }

    const addNewPolygon = () => {

        if(allProjectRealtys.length > 0){

            var attr = [...allProjectRealtys[0].attributes];
            for(let i in attr){
                attr[i] = {...attr[i],value:"",projectRealtyAttributeID:0,projectRealtyID:0};
            }

            if(allProjectRealtys.length > 0 && allProjectRealtys[0].projectRealtyID != 0){

                var arr2 = [...allProjectRealtys,{
                    attributes: attr,
                    color:"#49B01A",
                    active:1,
                    areaPath:"",
                    projectRealtyID:0,
                    projectID:props.projectID,
                    stateID:1,
                    priority:1
                }];
                setAllProjectRealtys(arr2);
            }

            setAddArea(true);
            setNewProjectRealtyID(null);
            
            listener = gogleMap.addListener('click', (event) => addLatLng(event, formData.color));
            setClicablePolygons(false); 

        }else{

            let notify      = new Notification();
            notify.setNotification(null,"Vyberte nejdříve relaitní typy a potom přidejte položku.",false,true,props.client);

        }
    }

    const changeColor = (color) => {

        color = (color.hex ? color.hex : color);

        projectRealtyLib.formHandle(color,formData,setFormData,"color");

        if(polygon){
            polygon.setOptions({
                strokeColor: color,
                fillColor: color,
            });
        }else{
            window.google.maps.event.removeListener(listener);
            listener = gogleMap.addListener('click', (event) => addLatLng(event, color));
        }

        
    }

    const removeProjectRealty = () => {

        if(polygon){
            deleteProjectRealty({
                variables:{
                    projectRealtyID: polygon.polyData.projectRealtyID
                }
            })
        }else{
            setClicablePolygons(true);
            setAddArea(false);
            window.google.maps.event.removeListener(listener);
        }

    }

    const setStatus = (stateID) => {

        var color = "#49B019";
        if(stateID == 2) color = "#FF8000";
        if(stateID == 3) color = "#D40101";

        if(polygon){
            polygon.setOptions({
                strokeColor: color,
                fillColor: color,
            });
        }else{
            window.google.maps.event.removeListener(listener);
            listener = gogleMap.addListener('click', (event) => addLatLng(event, color));
        }

        setFormData({...formData,stateID:stateID,color:color});
    }

    const setAttributeValue = (e,realtyAttributeID) => {

        const val = e.target.value;
        var arr = [...allProjectRealtys];

        for(let i in arr){
            if(arr[i].projectRealtyID == selectedProjectRealtyID || arr[i].projectRealtyID == 0){
                
                var arr2 = [...allProjectRealtys[i].attributes];
                for(let j in arr2){
                    if(arr2[j].realtyAttributeID == realtyAttributeID){
                        arr2[j] = {...arr2[j],value:val}
                    }
                }

                arr[i] = {...arr[i],attributes:arr2}
            }
        }

        setAllProjectRealtys(arr);

    }

    const setAttributePriority = (e,realtyAttributeID) => {

        var val = parseInt(e.target.value);
        val = (val ? val : "");

        var arr = [...allProjectRealtys];

        for(let i in arr){
                          
            var arr2 = [...arr[i].attributes];
            for(let j in arr2){
                if(arr2[j].realtyAttributeID == realtyAttributeID){
                    arr2[j] = {...arr2[j],priority:val}
                }
            }

            arr[i] = {...arr[i],attributes:arr2}
            
        }

        setAllProjectRealtys(arr);

    }

    const setRealtyAttribute = (e) => {
        const val = e.target.value;
        var checked = false;
        
        var arr = [...allProjectRealtys];
        for(let i in arr){
            if(arr[i].attributes){

                var attr = [...arr[i].attributes];
                for(let a in attr){
                    if(attr[a].realtyAttributeID == val){
                        checked = true;
                        if(!attr[a].deleted)attr[a] = {...attr[a],deleted:true}
                        else attr[a] = {...attr[a],deleted:false}
                    }
                }

                arr[i] = {...arr[i],attributes:attr}
            }
        }

        if(checked){
            setAllProjectRealtys(arr);
        }else{

            var arr2 = [...allProjectRealtys];

            if(arr2.length == 0){
                arr2[0] = {
                    attributes: [{
                        projectRealtyAttributeID:0,
                        projectRealtyID:0,
                        realtyAttributeID: val,
                        value:"",
                        priority:1,
                        deleted:false
                    }],
                    color:"#990000",
                    active:1,
                    areaPath:"",
                    projectRealtyID:0,
                    projectID:props.projectID,
                    stateID:1,
                    priority:1
                }
            }else{

                for(let i in arr2){

                    var attr2 = [...arr2[i].attributes];
                    attr2.push({
                        projectRealtyAttributeID:0,
                        projectRealtyID:0,
                        realtyAttributeID: val,
                        value:"",
                        priority:1,
                        deleted:false
                    })
                    
                    arr2[i] = {...arr2[i],attributes:attr2}
                }
            }
            setAllProjectRealtys(arr2);
        }

    }

    function polygonClick(){

        setAddArea(true);

        var arr = [...allProjectRealtys];
        
        for(let i in arr){
            if(arr[i].projectRealtyID == this.polyData.projectRealtyID){    

                setFormData({
                    stateID:  arr[i].stateID,
                    active:   arr[i].active,
                    color:    arr[i].color,
                    priority: arr[i].priority
                });

            }
        }

        setSelectedProjectRealtyID(this.polyData.projectRealtyID);
        setClicablePolygons(false);
        setEditablePolygons(false);

        for(let val of polygonList){
            if(val.id == this.id){
                polygon = val;
            }
        }

        polygon.setOptions({editable:true});
    }

    useEffect(() => {

        if(ApiKey == ""){

            ApiKey = 'AIzaSyDi-52zoEGGKqZpyJA9n5sXgWfYL6wH0gw';
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
            script.async = true;
            script.defer = true;
            script.id = "google-map-script";
            script.addEventListener('load', () => {
                setMapIsReady(true);
            });

            document.body.appendChild(script);
        }else{

            gogleMap = false;
            setMapIsReady(true);
            
 
        }
        
    },[]);

    if(mapIsReaddy && !gogleMap){

        gogleMap = new window.google.maps.Map(document.getElementById('map'), {
            center: {lat: props.formData.mapLat, lng: props.formData.mapLng},
            zoom: props.formData.mapZoom,
            mapTypeId: 'roadmap',
        });

        if(allProjectRealtys && allProjectRealtys.length > 0){
                
            var poly;
            var polyCoords;

            for(let i in allProjectRealtys){

                polyCoords = allProjectRealtys[i].areaPath.split(";");
                
                for(let p in polyCoords){
                    polyCoords[p] = polyCoords[p].split(",");
                    polyCoords[p] = {
                        lat: parseFloat(polyCoords[p][0]),
                        lng: parseFloat(polyCoords[p][1]),
                    }
                }

                poly = new window.google.maps.Polygon({
                    paths: polyCoords,
                    strokeColor: allProjectRealtys[i].color,
                    strokeOpacity: 1.0,
                    clickable:true,
                    strokeWeight: 3,
                    fillColor: allProjectRealtys[i].color,
                    fillOpacity: 0.35,
                    id: allProjectRealtys[i].projectRealtyID,
                    polyData:{
                        projectRealtyID: allProjectRealtys[i].projectRealtyID
                    }
                });
    
                poly.setMap(gogleMap);
                poly.addListener('click', polygonClick);

                polygonList.push(poly);

            }
        }
        gogleMap.addListener('dragend', (event) => {
            props.setFormData({...props.formData,
                mapZoom:gogleMap.getZoom(),
                mapLat:gogleMap.getCenter().toJSON().lat,
                mapLng:gogleMap.getCenter().toJSON().lng,
            })
        });
        gogleMap.addListener('zoom_changed', (event) => {
            props.setFormData({...props.formData,
                mapZoom:gogleMap.getZoom(),
                mapLat:gogleMap.getCenter().toJSON().lat,
                mapLng:gogleMap.getCenter().toJSON().lng,
            })
        });       
       
    }

    if(getLoading)loading = getLoading;
    if(deleteLoading)loading = deleteLoading;

    var projectRealtyIndex = -1;
    if(allProjectRealtys && allProjectRealtys.length > 0){
        for(let pr in allProjectRealtys){
            if(allProjectRealtys[pr].projectRealtyID == selectedProjectRealtyID || allProjectRealtys[pr].projectRealtyID == 0)projectRealtyIndex = pr;
        }
    }

    return(
        
        <>
            <div className="form-group">
                <p><strong>Vyberte které realitní typy chcete mít u daných položek projektu</strong></p>
                {data && data.allRealtyAttributes && data.allRealtyAttributes.map((item,index) => {
                    
                    var checked = false;
                   
                    if(allProjectRealtys && allProjectRealtys[0]){
                    
                        for(let att of allProjectRealtys[0].attributes){
                            
                            if(att.realtyAttributeID == item.realtyAttributeID && !att.deleted){
                                checked = true;
                            }
                        }
                    }

                    return (
                        <div key={index} className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={(e) => setRealtyAttribute(e)} checked={checked} id={"inlineCheckbox" + index} value={item.realtyAttributeID} />
                            <label className="form-check-label" htmlFor={"inlineCheckbox" + index}>{item.name}</label>
                        </div>
                    )
                })}
            </div>
            <div className="card main">
                <div className="card-header d-flex align-items-center">
                    Seznam položek projektu

                    {addArea ?
                        <button className="btn btn-blue btn-thiner ml-auto" onClick={() => savePolygon()}>Uložit položku</button>
                    :
                        <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addNewPolygon()}>Přidat položku</button>
                    }  
                    
                </div>
                <div className="card-body" style={{minHeight:wHeight-127+"px"}}>
                    {error ?
                        <Error text={error} />
                        :

                        <div className="row" >
                            <div className="col-12 col-sm-6 col-md-3">
                                <h5>Realitní typy</h5>

                                {addArea ?
                                    
                                    <div>

                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Active")}</label>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(formData.active == 1 ?  true : false)} onChange={(e) => setFormData({...formData,active:e.target.value})} />
                                                    <label className="form-check-label" htmlFor="active1">
                                                        Ano
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(formData.active == 0 ?  true : false)} onChange={(e) => setFormData({...formData,active:e.target.value})} />
                                                    <label className="form-check-label" htmlFor="active2">
                                                        Ne
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Priorita</label>
                                            <input type="text" value={formData.priority} className="form-control" name="priority" onChange={(e) => setFormData({...formData,priority:e.target.value})} />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Stav</label>
                                            <select value={formData.stateID} className="form-control" name="stateID" onChange={(e) => setStatus(e.target.value)}>
                                                {data && data.allStates && data.allStates.map((item,index) => (
                                                    <option key={index} value={item.stateID}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {projectRealtyIndex != -1 && allProjectRealtys[projectRealtyIndex].attributes.map((item,index) => {

                                            if(!item.deleted){

                                                var attrName  = "";
                                                for(let attrOriginal of data.allRealtyAttributes){
                                                    if(attrOriginal.realtyAttributeID == item.realtyAttributeID)attrName = attrOriginal.name;
                                                }

                                                return (
                                                    <div className="row">
                                                        <div className="col-12 col-sm-9">
                                                            <div key={index} className="form-group">
                                                                <label>{attrName}</label>
                                                                <input type="text" className="form-control" name="value" value={item.value} onChange={(e) => setAttributeValue(e,item.realtyAttributeID)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3" style={{paddingLeft:0}}>
                                                            <div key={index} className="form-group">
                                                                <label>Poz.</label>
                                                                <input type="text" className="form-control" name="priority" value={item.priority} onChange={(e) => setAttributePriority(e,item.realtyAttributeID)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }else{
                                                return null;
                                            }                                                            
 
                                        })}

                                        <div className="form-group">
                                            <button className="btn btn-danger" onClick={() => removeProjectRealty()} >Odstranit položku</button>
                                        </div>
                                    </div>

                                    :

                                    <div className="alert alert-warning">Pro zobrazení typů prosím klikněte na položku v mapě a nebo na tlačítko Přidat položku</div>
                                    
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-9" style={{minHeight:wHeight-167+"px"}}>
                                <div id="map"></div>
                                {loading &&
                                    <div className="card-body-loading">
                                        <Loading />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    
                </div>
            </div>    
            
        </>
        
    )
}

export default withApollo(ProjectRealty);