import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import moment from 'moment';
import AddEditDevelopmentDate from './AddEditDevelopmentDate';
import DevelopmentDatesLib from '../Library/developmentDate';
import {GET_DEVELOPMENT_DATES,DELETE_DEVELOPMENT_DATES} from '../Queries/developmentDate';
import edit from '../../../Public/Images/edit.svg';

var error = "";
var loading = false;

const DevelopmentDates = (props) => {

    const listVariables = {
        offset:0,
        limit:50,
        projectID:props.projectID
    }

	const [selectedDevelopmentDates,setSelectedDevelopmentDates]   = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]                     = useState(false);
    const [showAdd,setShowAdd] 			                             = useState(false);
    const [selectedDevelopmentDateID,setSelectedDevelopmentDateID] = useState(0);
    
    const {loading, error:getError, data:developmentDatesListData, fetchMore}   = useQuery(GET_DEVELOPMENT_DATES,{
        variables:listVariables
    });
 
	const [deleteDevelopmentDate, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DEVELOPMENT_DATES,{	
		update(cache,response) {            
            let developmentDates = new DevelopmentDatesLib(this);
            developmentDates.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteDevelopmentDates funkce, která posílá data na server
     * @param {*} projectDevelopmentDateID ID branchu
     */
    
    const initiateDeleteDevelopmentDate = (e,projectDevelopmentDateID) => {

        var checked = e.target.checked;
        var arr = [...selectedDevelopmentDates];

        if(!checked){
            for(let i in selectedDevelopmentDates){
                if(selectedDevelopmentDates[i] == projectDevelopmentDateID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [projectDevelopmentDateID,...arr];
        }

        setSelectedDevelopmentDates(arr);
  
    }

    const delDevelopmentDate = (action) => {

        if(action){

            let projectDevelopmentDateIDs = selectedDevelopmentDates.join(",");

            deleteDevelopmentDate({
                variables:{
                    projectDevelopmentDateIDs: projectDevelopmentDateIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedDevelopmentDates.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const openCloseModal = (open,projectDevelopmentDateID) => {
        setSelectedDevelopmentDateID(projectDevelopmentDateID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    {loading ? 

                        <Loading />
                
                        :
                        <>
                            <div className="card main">
                            
                                <div className="card-header d-flex align-items-center">
                                    {translate.t("DevelopmentDates")}
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                                </div>
                                <div className="card-body">

                                    {!error ?
                                        <div>
                                            <div className="data-list">
                                                
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Datum</div>
                                                        <div>Popis</div>
                                                        <div>Dokončena fáze</div>
                                                        <div className="text-right static small">{translate.t("Options")}</div>
                                                    </div>
                                                </div>
                                                <Fragment>
                                                    {developmentDatesListData && developmentDatesListData.allDevelopmentDates && developmentDatesListData.allDevelopmentDates.map((item,index) => {

                                                        var checked = false;
                                                        for(let i in selectedDevelopmentDates){
                                                            if(selectedDevelopmentDates[i] == item.projectDevelopmentDateID)checked = true;
                                                        }
                                                                                                                        
                                                        return (
                                                        
                                                            <div key={index} className="data-list-item-content">
                                                                <div>{moment(item.date).format("MM/YYYY")}</div>
                                                                <div>{item.name}</div>   
                                                                <div>{(item.completed == 1 ? "Ano" : "Ne")}</div>                                                      
                                                                <div className="text-right static small">
                                                                    <img onClick={() => openCloseModal(true,item.projectDevelopmentDateID)} className="edit-icon" src={edit} />
                                                                    <input className="delete-checked" type="checkbox" name="deleteDevelopmentDate[]" checked={checked} onChange = {(e) => initiateDeleteDevelopmentDate(e,item.projectDevelopmentDateID)} />
                                                                </div>
                                                            </div>
                                                                    
                                                        )
                                                        
                                                    })} 
                                                </Fragment>
                                            </div>
                                            {!loading ? 
                                                <Fragment>
                                                    {developmentDatesListData && developmentDatesListData.allDevelopmentDates && developmentDatesListData.allDevelopmentDates.length == 0 ?
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                        :  
                                                        <Fragment>     
                                                            {!loading && developmentDatesListData && developmentDatesListData.allDevelopmentDates.length < developmentDatesListData.branchListCount &&                                 
                                                                <div className="text-center p-5">
                                                                    <button className="btn btn-primary" onClick={() => fetchMore({
                                                                        variables: {...listVariables,
                                                                            offset: developmentDatesListData.allDevelopmentDates.length
                                                                        },
                                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                                            if (!fetchMoreResult) return prev;
                                                                            return Object.assign({}, prev, {
                                                                                allDevelopmentDates: [...prev.allDevelopmentDates, ...fetchMoreResult.allDevelopmentDates]
                                                                            });
                                                                        }
                                                                    })}> {translate.t("LoadMore")} </button>
                                                                </div> 
                                                            }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                                :                                    
                                                <Loading />                                    
                                            }
                                            
                                        </div>
                                        :
                                        <Fragment>
                                            {error &&  <Error text={error} />}
                                        </Fragment>
                                    }
                                </div>
                            </div>

                            {showAdd &&
                                <AddEditDevelopmentDate openCloseModal={openCloseModal} projectID = {props.projectID} selectedDevelopmentDateID={selectedDevelopmentDateID} listVariables={listVariables} />
                            }
                            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delDevelopmentDate} /> }

                        </>

                    }
                
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(DevelopmentDates);









