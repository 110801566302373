import React,{ Fragment, useState } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import moment from 'moment';
import AddEditProjectVideo from './AddEditProjectVideo';
import ProjectVideosLib from '../Library/projectVideo';
import {GET_PROJECT_VIDEOS,DELETE_PROJECT_VIDEOS} from '../Queries/projectVideo';
import edit from '../../../Public/Images/edit.svg';

var error = "";
var loading = false;

const ProjectVideos = (props) => {

    const listVariables = {
        offset:0,
        limit:50,
        projectID:props.projectID
    }

	const [selectedProjectVideos,setSelectedProjectVideos]   = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]                     = useState(false);
    const [showAdd,setShowAdd] 			                             = useState(false);
    const [selectedProjectVideoID,setSelectedProjectVideoID] = useState(0);
    
    const {loading, error:getError, data:projectVideosListData, fetchMore}   = useQuery(GET_PROJECT_VIDEOS,{
        variables:listVariables
    });
 
	const [deleteProjectVideo, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_PROJECT_VIDEOS,{	
		update(cache,response) {            
            let projectVideos = new ProjectVideosLib(this);
            projectVideos.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    /**
     * smazání štítků
     * @param {*} deleteProjectVideos funkce, která posílá data na server
     * @param {*} projectVideoID ID branchu
     */
    
    const initiateDeleteProjectVideo = (e,projectVideoID) => {

        var checked = e.target.checked;
        var arr = [...selectedProjectVideos];

        if(!checked){
            for(let i in selectedProjectVideos){
                if(selectedProjectVideos[i] == projectVideoID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [projectVideoID,...arr];
        }

        setSelectedProjectVideos(arr);
  
    }

    const delProjectVideo = (action) => {

        if(action){

            let projectVideoIDs = selectedProjectVideos.join(",");

            deleteProjectVideo({
                variables:{
                    projectVideoIDs: projectVideoIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedProjectVideos.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const openCloseModal = (open,projectVideoID) => {
        setSelectedProjectVideoID(projectVideoID);
        setShowAdd(open);
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    {loading ? 

                        <Loading />
                
                        :
                        <>
                            <div className="card main">
                            
                                <div className="card-header d-flex align-items-center">
                                    Videa
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                                </div>
                                <div className="card-body">

                                    {!error ?
                                        <div>
                                            <div className="data-list">
                                                
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Video</div>
                                                        <div>Popis</div>
                                                        <div className="text-right static small">{translate.t("Options")}</div>
                                                    </div>
                                                </div>
                                                <Fragment>
                                                    {projectVideosListData && projectVideosListData.allProjectVideos && projectVideosListData.allProjectVideos.map((item,index) => {

                                                        var checked = false;
                                                        for(let i in selectedProjectVideos){
                                                            if(selectedProjectVideos[i] == item.projectVideoID)checked = true;
                                                        }
                                                                                                                        
                                                        return (
                                                        
                                                            <div key={index} className="data-list-item-content">
                                                               
                                                                <div>
                                                                <iframe width="250" height="150" src={"https://www.youtube.com/embed/" + item.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>   
                                                                <div>{item.description}</div>                                                      
                                                                <div className="text-right static small">
                                                                    <img onClick={() => openCloseModal(true,item.projectVideoID)} className="edit-icon" src={edit} />
                                                                    <input className="delete-checked" type="checkbox" name="deleteProjectVideo[]" checked={checked} onChange = {(e) => initiateDeleteProjectVideo(e,item.projectVideoID)} />
                                                                </div>
                                                            </div>
                                                                    
                                                        )
                                                        
                                                    })} 
                                                </Fragment>
                                            </div>
                                            {!loading ? 
                                                <Fragment>
                                                    {projectVideosListData && projectVideosListData.allProjectVideos && projectVideosListData.allProjectVideos.length == 0 ?
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                        :  
                                                        <Fragment>     
                                                            {!loading && projectVideosListData && projectVideosListData.allProjectVideos.length < projectVideosListData.branchListCount &&                                 
                                                                <div className="text-center p-5">
                                                                    <button className="btn btn-primary" onClick={() => fetchMore({
                                                                        variables: {...listVariables,
                                                                            offset: projectVideosListData.allProjectVideos.length
                                                                        },
                                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                                            if (!fetchMoreResult) return prev;
                                                                            return Object.assign({}, prev, {
                                                                                allProjectVideos: [...prev.allProjectVideos, ...fetchMoreResult.allProjectVideos]
                                                                            });
                                                                        }
                                                                    })}> {translate.t("LoadMore")} </button>
                                                                </div> 
                                                            }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                                :                                    
                                                <Loading />                                    
                                            }
                                            
                                        </div>
                                        :
                                        <Fragment>
                                            {error &&  <Error text={error} />}
                                        </Fragment>
                                    }
                                </div>
                            </div>

                            {showAdd &&
                                <AddEditProjectVideo openCloseModal={openCloseModal} projectID = {props.projectID} selectedProjectVideoID={selectedProjectVideoID} listVariables={listVariables} />
                            }
                            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delProjectVideo} /> }

                        </>

                    }
                
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(ProjectVideos);









