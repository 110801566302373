import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_PROJECT_HISTORY, GET_PROJECT_HISTORY} from '../Queries/projectHistory';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import ProjectHistoryLib from '../Library/projectHistory';
import DatePicker,{ registerLocale } from 'react-datepicker';
import {PROJECT_HISTORY_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import ProjectHistoryImages from './ProjectHistoryImages';

const AddEditProjectHistory = (props) => {

    const projectHistory = new ProjectHistoryLib(props);

    const [selectedTab, setTab] = useState(1);
    const [mainPhoto, setMainPhoto] = useState("");
    const [formData,setFormData] = useState({
        
        projectHistoryID:props.selectedProjectHistoryID,
        projectID: props.projectID,
        description:"",
        name:"",
        photo:""
        
    });

    const {data:branchData,loading:getLoading,error:getError} = useQuery(GET_PROJECT_HISTORY,{
        variables:{
            projectHistoryID: formData.projectHistoryID
        },
        fetchPolicy: 'network-only',
        onCompleted(data){

            projectHistory.getData(data,formData.projectHistoryID,formData,setFormData);

            if(formData.projectHistoryID){
                setMainPhoto(SERVER_URL + "/" + PROJECT_HISTORY_IMAGE_URL + "/ph-" + formData.projectHistoryID + "/velka_" + data.projectHistory.photo);
            }
            
        },
    })

    const [addProjectHistory,{data:addBranchData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_PROJECT_HISTORY,{
        onCompleted(data){
            
            if(!formData.projectHistoryID){
                setFormData({...formData,projectHistoryID:data.addEditProjectHistory.projectHistoryID,photo:""})
                setTab(2);
            }else{
                props.openCloseModal(false);
            }
            
		},update(cache,response) {    
            
            projectHistory.updateList(cache,response,props.listVariables,formData.projectHistoryID);
            
            let notify = new Notification();
            if(formData.projectHistoryID && formData.projectHistoryID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    });

    const openMainImage = (e) => {

        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            setMainPhoto(dataURL);
            setFormData({...formData,photo:file});
        };
        reader.readAsDataURL(file);
    }

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.projectHistoryID && formData.projectHistoryID != 0 ? "Upravit milník" : "Přidat milník")}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => setTab(1)}>{translate.t("MainData")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (formData.projectHistoryID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(formData.projectHistoryID)setTab(2)}}>{translate.t("Images")}</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
 

                                        {selectedTab == 1 &&
                                            <div className="row">   

                                                <div className="col-6">

                                                    <label className="input-label">Hlavní fotka</label>

                                                    <div className="form-group input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="customFile" required onChange={(e) => openMainImage(e)} />
                                                            <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                        </div>
                                                    </div>
                                                    {mainPhoto != "" &&
                                                        <div className="form-group main-photo-container">
                                                            
                                                            <div className="one-image">            
                                                                <img id="main-image" src={mainPhoto} />
                                                            </div>

                                                        </div>
                                                    }

                                                </div> 
                                                                                    
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">*Název milníku</label>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="name" value={formData.name} onChange={(e) => projectHistory.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                
                                                    <div className="form-group">
                                                        <label className="input-label">*Popis</label>
                                                        <div className="form-group">
                                                            <textarea type="text" className="form-control" name="description" value={formData.description} onChange={(e) => projectHistory.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>                                                
                                                </div> 
                            
                                            </div> 
                                        }

                                        {selectedTab == 2 &&
                                            <ProjectHistoryImages projectHistoryID = {formData.projectHistoryID} />
                                        }

                                    </div>
                                        
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => projectHistory.addProjectHistory(addProjectHistory,formData)}>{(formData.projectHistoryID && formData.projectHistoryID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditProjectHistory);