/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component, Fragment } from 'react';
import { NavLink } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { withRouter } from 'react-router-dom';
import logo from '../Public/Images/logo.svg';
import menu from '../Public/Images/menu.svg';
import door from '../Public/Images/door.svg';
import cars from '../Public/Images/cars.svg';
import project from '../Public/Images/project.svg';
import content from '../Public/Images/content.svg';
import doorLighter from '../Public/Images/door_lighter.svg';
import settings from '../Public/Images/cogwheel.svg';
import { ApolloConsumer} from 'react-apollo';
import translate from '../Modules/Translations/index';
import { withApollo } from 'react-apollo'; 
import {ADMIN_USER} from '../GlobalQueries/globalQueries';

const INITIAL_MENU = [
	{menuID:1,show:false},
	{menuID:2,show:false},
	{menuID:3,show:false},
	{menuID:4,show:false},
	{menuID:5,show:false},
	{menuID:6,show:false},
	{menuID:7,show:false}
]

const INITIAL_STATE = {
	cl:"",
	roleID:0,
	showSubmenu:INITIAL_MENU
}

class Header extends Component {
	
	  constructor(props){
		  super(props);
		  this.showSideBar   = this.showSideBar.bind(this);
		  this.hideSideBar   = this.hideSideBar.bind(this);
		  this.toogleSubmenu = this.toogleSubmenu.bind(this);
		  
		  this.logout      = this.logout.bind(this);
		  this.state = {...INITIAL_STATE};
		  
	  }
	  componentDidMount(){
		  
		const adminUserData = this.props.client.readQuery({ query:ADMIN_USER });
		this.setState({roleID:adminUserData.adminUser.roleID});
	  }
	  showSideBar(e){
		  e.preventDefault();
		  if(this.state.cl){
			  this.setState({cl:""});
		  }else{
			  this.setState({cl:"open"});
		  }
	  }
	  hideSideBar(e){
		  this.setState({cl:""});
	  }
	  
	  logout(e,client){	  

		  e.preventDefault();
		  client.resetStore();

		  localStorage.removeItem("token");
		  localStorage.removeItem("loggedUser");

		  window.location.reload();

	  }
	  
	  toogleSubmenu(e,menu,prevent){
		  		  
		  if(prevent)e.preventDefault();
			  
		  var arr = this.state.showSubmenu.map(function(item,index){
			  if(index !== menu)return {...item,show:false};
			  else return {...item,show:!item.show};
		  });
		  
		  if(prevent)this.setState({...this.state,showSubmenu:arr});
		  else this.setState({...this.state,showSubmenu:arr,cl:''});
	  }
	  
	  handleClickOutside = evt => {
		 	this.setState({...this.state,showSubmenu:INITIAL_MENU});
	  };
	
	  render() {

		const {cl,roleID} = this.state;	
		const authUser = localStorage.getItem('loggedUser');	

	    return (
			<div id="header" className="mb-1">
				<ul className="d-flex m-0 p-0 main">
					<li><button tabIndex="0" onClick={this.showSideBar} ><img src={menu} /></button></li>
					<li className="logo"><img alt={translate.t("UrbanBiatlon")} src={logo} /></li>
					<li className="user ml-auto">
						<NavLink to="/settings/change-password">
							{authUser}
						</NavLink></li>
					<li className="">
						<ApolloConsumer>
							{client => (
								<button onClick={(e) => this.logout(e,client)}><img src={door} /></button>
							)}
								
						</ApolloConsumer>
					</li>

				</ul>
				<div className={"sideBar " + cl}>
					<ul>
												
						<li>
							<NavLink onClick={(e) => this.toogleSubmenu(e,6,true)} activeClassName="active" to="/projects"><img src={project} /> <span>{translate.t("Projects")}</span></NavLink>
							<ul className={(this.state.showSubmenu[6].show) ? '' : 'hidden' }>
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/projects"> {translate.t("ProjectsList")}</NavLink></li>
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/homepage-projects"> Projekty na hlavní stránce</NavLink></li>
								<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/projects/information-attributes"> {translate.t("InformationAttributes")}</NavLink></li>
								<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/projects/realty-attributes"> {translate.t("RealtyAttributes")}</NavLink></li>
							</ul>
						</li>
						{false &&
							<li>
								<NavLink onClick={(e) => this.toogleSubmenu(e,3,true)} strict to="/content"><img src={content} /> <span>{translate.t("Content")}</span></NavLink>
								<ul className={(this.state.showSubmenu[3].show) ? '' : 'hidden' }>
									<li><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/link"> {translate.t("LinkList")}</NavLink></li> 
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/app-link"> {translate.t("AppLinkList")}</NavLink></li>
									<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/app-banner"> {translate.t("AppBannerList")}</NavLink></li>
									{false && <li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/content/article"> {translate.t("ArticleList")}</NavLink></li>}
									
								</ul>
							</li>
	  					}
						
						<li>
							<NavLink onClick={(e) => this.toogleSubmenu(e,4,true)} activeClassName="active" to="/settings"><img src={settings} /> <span>{translate.t("Settings")}</span></NavLink>
							<ul className={(this.state.showSubmenu[4].show) ? '' : 'hidden' }>
								
								<li className="last"><NavLink onClick={this.handleClickOutside} exact activeClassName="active" to="/settings/change-password"> {translate.t("ChangePwd")}</NavLink></li>
							</ul>
						</li>
						<li>
							<ApolloConsumer>
								{client => (
									<button onClick={(e) => this.logout(e,client)}><img src={doorLighter} /> <span>{translate.t("LogOut")}</span></button>
								)}
								
							</ApolloConsumer>
						</li>

					</ul>
				</div>
			</div>
	    );
	  }
  
}


export default withApollo(withRouter(onClickOutside(Header)));
