import {GET_PROJECT_VIDEOS} from '../Queries/projectVideo';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import moment from 'moment';

class ProjectVideo {

    constructor(props){
        this.props = props;
    }

    addProjectVideo(addProjectVideo,formData){

        if(formData.url != ""){
  
            addProjectVideo({
                variables:{
                    projectVideoID: formData.projectVideoID,
                    projectID:      formData.projectID,
                    url:            formData.url,
                    description:    formData.description,
        
                }
            }); 

        }else{
            let notify = new Notification();
            notify.setNotification(null,"Nevyplnili jste odkaz videa.",false,true,this.props.client);
        }

    }

    getData(data,projectVideoID,formData,setFormData){

        if(projectVideoID != 0){
            
            setFormData({...formData,
                projectID:    formData.projectID,
                url:          "https://www.youtube.com/watch?v=" + data.projectVideo.url,
                description:  data.projectVideo.description,
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,projectVideoID){

        const { allProjectVideos,projectVideoListCount } = cache.readQuery({ query: GET_PROJECT_VIDEOS ,variables});       
        
        if(projectVideoID && projectVideoID != 0){

            var arr = [...allProjectVideos];

            arr.forEach((item,index) => {
                
                if(item.projectVideoID == response.data.addEditProjectVideo.projectVideoID){
                    arr[index] = response.data.addEditProjectVideo;
                }

            });

            cache.writeQuery({ 
                query:GET_PROJECT_VIDEOS,
                variables,
                data:{
                    allProjectVideos: arr,
                    projectVideoListCount :projectVideoListCount
                } 
            });
        

        }else{
            
            var arr = [...allProjectVideos,response.data.addEditProjectVideo];

            cache.writeQuery({ 
                query:GET_PROJECT_VIDEOS,
                variables,
                data:{
                    allProjectVideos: arr,
                    projectVideoListCount :projectVideoListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteProjectVideos.split(",");

        const { allProjectVideos,projectVideoListCount } = cache.readQuery({ query: GET_PROJECT_VIDEOS ,variables});
        var arr = [...allProjectVideos];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.projectVideoID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_PROJECT_VIDEOS,
            variables,
            data:{
                allProjectVideos: [...arr],
                projectVideoListCount :projectVideoListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default ProjectVideo;