import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {GET_PROJECT_FILES,DELETE_PROJECT_FILES,UPLOAD_PROJECT_FILES} from '../Queries/projectFile';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import {SERVER_URL,PROJECT_FILE_URL} from '../../../Config/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import ProjectFileLib from '../Library/projectFile';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import xlsFile from '../../../Public/Images/xls.svg';
import docFile from '../../../Public/Images/doc.svg';
import pdfFile from '../../../Public/Images/pdf.svg';
import txtFile from '../../../Public/Images/txt.svg';


const ProjectFiles = (props) => {

    const projectPhotoLib = new ProjectFileLib(props);

    const [showDeleteInfo,setDeleteInfo] = useState(false);
    const [projectFilesID,setProjectFiles] = useState([]);

    var {data,loading,error:getError} = useQuery(GET_PROJECT_FILES,{
        variables:{
            projectID:props.projectID
        }
    })

    const [deleteProjectFiles,{loading:deleteLoading}] = useMutation(DELETE_PROJECT_FILES,{
        variables:{
            projectID: props.projectID
        },
        update(cache,response) {
            projectPhotoLib.updateProjectFilesAfterDelete(cache,response,props.projectID);
        }
    })
    
    const [uploadProjectFiles,{error:uploadError,loading:uploadLoading}] = useMutation(UPLOAD_PROJECT_FILES,{
        update(cache,response) {
            projectPhotoLib.updateProjectFiles(cache,response,props.projectID);
        }
    });

    const showDelNotifi = () => {
        if(projectFilesID.length > 0){
            setDeleteInfo(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,props.client);
        }
    }

    const delProjectFile = (action) => {

        if(action){

            deleteProjectFiles({variables:{projectFilesIDs:projectFilesID}});
        }

        setDeleteInfo(false);
    }

    var error = "";
    if(getError || uploadError){

        var err = getError || uploadError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(err);
    }

    if(deleteLoading)loading = deleteLoading;
    if(uploadLoading)loading = uploadLoading;

    console.log(data);

    return(

        <Fragment>

            {error ?
                <Error text={error} />
                 :
                <Fragment>

                    <div className="form-group input-group">
                        <div className="custom-file">
                            
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                required
                                multiple
                                onChange={
                                    ({ target: { validity, files: files } }) => {
                                        validity.valid && uploadProjectFiles({ variables: { files:files, projectID: props.projectID } });
                                    }   
                                }
                            />
                            <label className="custom-file-label" htmlFor="customFile">Vyberte soubory</label>
                        </div>
                        <div className="input-group-append">
                            <button className="btn btn-danger btn-delete-files" type="button" onClick={() => showDelNotifi()}>{translate.t("DeleteSelectedPhotos")}</button>
                        </div>
                    </div>

                    {loading ? 

                        <Loading />
                
                        :
                
                        <div className="row">
                    
                            {data.projectFiles && data.projectFiles.map((item,index) => {

                                for(let i in props.formData.files){
                                    
                                    if(item.projectFileID == props.formData.files[i].projectFileID){
                                        if(props.formData.files[i].description || props.formData.files[i].description == "")item.description = props.formData.files[i].description;
                                        
                                    }
                                }
                        
                                return(
                                    <div key={index} className="col-2 form-group">
                                        <div className="image-item">
                                            <div className="menu-item">
                                                <input type="checkbox" name="delete" onChange={(e) => projectPhotoLib.setDeleteFile(e,item.projectFileID,projectFilesID,setProjectFiles)} />
                                            </div>
                                            

                                            {item.type == "xls" &&
                                                <img src={xlsFile} />
                                            }
                                            {item.type == "doc" &&
                                                <img src={docFile} />
                                            }
                                            {item.type == "pdf" &&
                                                <img src={pdfFile} />
                                            }
                                            {item.type != "pdf" && item.type != "doc" && item.type != "xls" &&
                                                <img src={txtFile} />
                                            }
                                                                                        

                                        </div>
                                        <div style={{overflow:"hidden",fontSize:'10px',whiteSpace:'nowrap',textOverflow: "ellipsis"}}>{item.file}</div>
                                        <div>
                                            <input className="form-control" placeholder="popis" type="text" name="description" value={item.description} onChange={(e) => projectPhotoLib.setFiles(e,item.projectFileID,props.formData,props.setFormData)} />
                                        </div>
                                    </div>
                                )
                            })}         
                    
                        </div>

                    }
                
                    {showDeleteInfo && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané obrázky ?'} callback={delProjectFile} />}
                    
                </Fragment>
            }
        
        </Fragment>
    )

}

export default withApollo(ProjectFiles);









