/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_PROJECT_VIDEO = gql`
    mutation AddEditProjectVideo($projectVideoID:ID,$projectID:ID,$url:String,$description:String){
        addEditProjectVideo(projectVideoID:$projectVideoID,projectID:$projectID,url:$url,description:$description){
            projectVideoID          
            projectID
            url
            description
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_PROJECT_VIDEOS = gql`
    query AllProjectVideos($offset:Int,$limit:Int,$projectID:ID){
        allProjectVideos(offset:$offset,limit:$limit,projectID:$projectID){ 
            projectVideoID          
            projectID
            url
            description
        }
        projectVideoListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_PROJECT_VIDEO = gql`
    query ProjectVideo($projectVideoID:ID){
        projectVideo(projectVideoID:$projectVideoID){            
            projectVideoID          
            projectID
            url
            description
        }
       
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_PROJECT_VIDEOS = gql`
    mutation deleteProjectVideos($projectVideoIDs:ID!) {
        deleteProjectVideos(projectVideoIDs:$projectVideoIDs)
    }
`;