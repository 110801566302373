/**
 * soubor se seznamem veškerých graphql dotazů pro obrázky
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých obrázku jídla
 */
export const GET_PROJECT_IMAGES = gql`
    query ProjectImages($projectID: ID!){
        projectImages(projectID:$projectID){
            projectImageID
            name
            description
        }

    }
`;

/**
 * vymazání obrázku jídla
 */
export const DELETE_PROJECT_IMAGES = gql`
    mutation deleteProjectImages($projectImagesIDs:[ID]!,$projectID:ID!) {
        deleteProjectImages(projectImagesIDs:$projectImagesIDs,projectID:$projectID)
    }
`;


/**
 * Upload obrázku pro jídlo
 */
export const UPLOAD_PROJECT_IMAGES = gql`
  mutation uploadProjectImages($files: Upload!,$projectID:ID!) {
    uploadProjectImages(files: $files,projectID:$projectID){
        projectImageID
        name
        description
    } 
  }
`; 


