/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_DEVELOPMENT_DATE = gql`
    mutation AddEditDevelopmentDate($projectDevelopmentDateID:ID,$projectID:ID,$date:String,$name:String,$completed:Int){
        addEditDevelopmentDate(projectDevelopmentDateID:$projectDevelopmentDateID,projectID:$projectID,date:$date,name:$name,completed:$completed){
            projectDevelopmentDateID          
            projectID
            date
            name
            completed
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_DEVELOPMENT_DATES = gql`
    query AllDevelopmentDates($offset:Int,$limit:Int,$projectID:ID){
        allDevelopmentDates(offset:$offset,limit:$limit,projectID:$projectID){ 
            projectDevelopmentDateID          
            projectID
            date
            name
            completed
        }
        developmentDateListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_DEVELOPMENT_DATE = gql`
    query DevelopmentDate($projectDevelopmentDateID:ID){
        developmentDate(projectDevelopmentDateID:$projectDevelopmentDateID){            
            projectDevelopmentDateID          
            projectID
            date
            name
            completed
        }
       
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_DEVELOPMENT_DATES = gql`
    mutation deleteDevelopmentDates($projectDevelopmentDateIDs:ID!) {
        deleteDevelopmentDates(projectDevelopmentDateIDs:$projectDevelopmentDateIDs)
    }
`;