/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_PROJECT_HISTORY = gql`
    mutation AddEditProjectHistory($projectHistoryID:ID,$projectID:ID,$description:String,$name:String,$photo:Upload){
        addEditProjectHistory(projectHistoryID:$projectHistoryID,projectID:$projectID,description:$description,name:$name,photo:$photo){
            projectHistoryID          
            projectID
            name
            description
            photo
        }
    }
`;


/**
 *  Seznam veškerých athleteů
 */
export const GET_ALL_PROJECT_HISTORY = gql`
    query AllProjectHistory($projectID:ID){
        allProjectHistory(projectID:$projectID){ 
            projectHistoryID          
            projectID
            name
            description
        }
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_PROJECT_HISTORY = gql`
    query ProjectHistory($projectHistoryID:ID){
        projectHistory(projectHistoryID:$projectHistoryID){            
            projectHistoryID          
            projectID
            name
            description
            photo
        }
       
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_PROJECT_HISTORY = gql`
    mutation deleteProjectHistory($projectHistoryIDs:ID!) {
        deleteProjectHistory(projectHistoryIDs:$projectHistoryIDs)
    }
`;