import {GET_DEVELOPMENT_DATES} from '../Queries/developmentDate';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import moment from 'moment';

class Branch {

    constructor(props){
        this.props = props;
    }

    addDevelopmentDate(addDevelopmentDate,formData){

        if(formData.date != ""){
  
            if(formData.name != ""){
  
                addDevelopmentDate({
                    variables:{
                        projectDevelopmentDateID:   formData.projectDevelopmentDateID,
                        projectID:                  formData.projectID,
                        date:                       moment(formData.date).format("YYYY-MM-DD 00:00:00"),
                        name:                       formData.name,
                        completed:                  parseInt(formData.completed),
            
                    }
                }); 
    
            }else{
                let notify = new Notification();
                notify.setNotification(null,"Nevyplnili jste popis.",false,true,this.props.client);
            }

        }else{
            let notify = new Notification();
            notify.setNotification(null,"Nevyplnili jste datum.",false,true,this.props.client);
        }

    }

    getData(data,projectDevelopmentDateID,formData,setFormData){

        if(projectDevelopmentDateID != 0){
            
            setFormData({...formData,
                projectID:                  formData.projectID,
                date:                       moment(data.developmentDate.date).toDate(),
                name:                       data.developmentDate.name,
                completed:                  data.developmentDate.completed,
            });
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateList(cache, response,variables,projectDevelopmentDateID){

        const { allDevelopmentDates,developmentDateListCount } = cache.readQuery({ query: GET_DEVELOPMENT_DATES ,variables});       
        
        if(projectDevelopmentDateID && projectDevelopmentDateID != 0){

            var arr = [...allDevelopmentDates];

            arr.forEach((item,index) => {
                
                if(item.projectDevelopmentDateID == response.data.addEditDevelopmentDate.projectDevelopmentDateID){
                    arr[index] = response.data.addEditDevelopmentDate;
                }

            });

            cache.writeQuery({ 
                query:GET_DEVELOPMENT_DATES,
                variables,
                data:{
                    allDevelopmentDates: arr,
                    developmentDateListCount :developmentDateListCount
                } 
            });
        

        }else{
            
            var arr = [...allDevelopmentDates,response.data.addEditDevelopmentDate];

            cache.writeQuery({ 
                query:GET_DEVELOPMENT_DATES,
                variables,
                data:{
                    allDevelopmentDates: arr,
                    developmentDateListCount :developmentDateListCount
                } 
            });
        }

    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteDevelopmentDates.split(",");

        const { allDevelopmentDates,developmentDateListCount } = cache.readQuery({ query: GET_DEVELOPMENT_DATES ,variables});
        var arr = [...allDevelopmentDates];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.projectDevelopmentDateID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_DEVELOPMENT_DATES,
            variables,
            data:{
                allDevelopmentDates: [...arr],
                developmentDateListCount :developmentDateListCount - resp.length
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

}

export default Branch;