/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * add/edit kategorie
 */
export const ADD_EDIT_INFORMATION_ATTRIBUTE = gql`
    mutation AddEditInformationAttribute($informationAttributeID:ID,$name:String){
        addEditInformationAttribute(informationAttributeID:$informationAttributeID,name:$name){
            informationAttributeID
            name
        }
    }
`;


/**
 *  Data kategorie
 */

export const GET_INFORMATION_ATTRIBUTE = gql`
    query InformationAttribute($informationAttributeID: ID!){
        informationAttribute(informationAttributeID:$informationAttributeID){
            informationAttributeID
            name     
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_INFORMATION_ATTRIBUTES = gql`
    query AllInformationAttributes{
        allInformationAttributes{            
            informationAttributeID
            name   
        }
    }
`;

/**
 * Smazání kategorie
 */

export const DELETE_INFORMATION_ATTRIBUTES = gql`
    mutation deleteInformationAttributes($informationAttributeIDs:ID!) {
        deleteInformationAttributes(informationAttributeIDs:$informationAttributeIDs)
    }
`;