import React,{ Component, Fragment, useState } from 'react';
import {useMutation, useQuery,withApollo} from 'react-apollo';
import {ADD_EDIT_INFORMATION_ATTRIBUTE, GET_INFORMATION_ATTRIBUTE,GET_INFORMATION_ATTRIBUTES} from '../Queries/informationAttribute';
import translate from '../../Translations/index';
import Notification from '../../../Library/notification';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import InformationAttributeLib from '../Library/informationAttribute';


const AddEditInformationAttribute = (props) => {

    const informationAttributeLib = new InformationAttributeLib(props);

    const [formData,setFormData] = useState({
        informationAttributeID:props.selectedInformationAttributeID,
        name:""
    });

    const {data:catData,loading:getLoading,error:getError} = useQuery(GET_INFORMATION_ATTRIBUTE,{
        variables:{informationAttributeID: formData.informationAttributeID},
        fetchPolicy: 'network-only',
        onCompleted(data){
            informationAttributeLib.getData(data,formData.informationAttributeID,formData,setFormData);
        },
    })

    const [addEditInformationAttribute,{data:addInformationAttributeData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_INFORMATION_ATTRIBUTE,{
        onCompleted(data){
			props.openCloseModal(false);
            
		},update(cache,response) {    
            
            informationAttributeLib.updateList(cache, response,formData.informationAttributeID);
            
            let notify = new Notification();
            if(formData.informationAttributeID && formData.informationAttributeID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);

		},onError(err){
            const helper    = new Helper(); 
            err             = helper.getApolloErrorText(err);
            let notify      = new Notification();
            notify.setNotification(null,err,false,true,props.client);
        }
    })

    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.informationAttributeID && formData.informationAttributeID != 0 ? translate.t("EditInformationAttribute") : translate.t("AddInformationAttribute"))}</h4>
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>  
                                    <div className="row">    
                                                                            
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Name")} </label>
                                                <div className="form-group">
                                                    <input className="form-control" athlete="text" name="name" value={formData.name} onChange={(e) => informationAttributeLib.formHandle(e,formData,setFormData)} />
                                                </div>
                                            </div>                                                
                                        </div> 
                       
                                    </div> 
                                </Fragment> 
                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => informationAttributeLib.addInformationAttribute(addEditInformationAttribute,formData)}>{(formData.informationAttributeID && formData.informationAttributeID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )

}

export default withApollo(AddEditInformationAttribute);